import React, { useEffect, useState } from 'react'
import Breadcrumbpage from '../commonpage/Breadcrumbpage'
import Followus from '../footer/Followus'
import Footer from "../footer/Footer";
import Headertop from '../header/Headertop'
import Headermain from '../header/Headermain'
import Marqueepagee from '../marquee/Marqueepagee'
import { fetchAwardBanner, fetchAwardList } from '../../controler/award/award';
// import Header1 from '../Header1'
// import Innerbanner from './Innerbanner';
import { imageUrl } from '../../controler/ApiHelper';

function Awards() {

	var url = "http://localhost:8070";
	const[awardlist, setAwardList] = useState([]);
	const[awardbanner, setAwardBanner] =useState([])

	useEffect(() => {
		async function getawardlistData(){
			const data = await fetchAwardList();
			setAwardList(data.data);
		}
		getawardlistData();
		async function awardbanner(){
			const data = await fetchAwardBanner()
			setAwardBanner(data.data[0])
			console.log("award banner data", data.data)
		}
		awardbanner();
	}, [])
  return (
    <React.Fragment>
        <Headertop />
		<div className="innerbanner-main">
				<Headermain />
				<div id="myCarousel" className="carousel slide" data-ride="carousel">
					<div className="carousel-inner">
						<div className="item active">
						<img src={url+awardbanner.bannerImg} width="0" height="0" alt="" title=""/>
						<div className="banner-content hidden-xs">
							<div className="homebannercontent">
								<h1>{awardbanner.title}</h1>
								<p>{awardbanner.description}</p>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
		<Marqueepagee />
		<Breadcrumbpage />
        <div className="ourawardspage-main" data-aos="fade-up" data-aos-duration="1500">
			<div className="container">
				<div className="row">
					{awardlist.map((awardlist) => {
						return(
							<div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="500">
								<div className="awards-div">
									<img src={imageUrl+awardlist.awardImg} width="0" height="0" alt="" title=""/>
									<h4>{awardlist.title}</h4>
									<p>{awardlist.description}</p>
								</div>
							</div>
						)
					})}
					{/* <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="500">
						<div className="awards-div">
							<img src="assets/images/award-img1.png" width="0" height="0" alt="" title=""/>
							<h4>Winner</h4>
							<p>Vivamus porttitor nunc varius elementum finibus.</p>
						</div>
					</div>
					<div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1000">
						<div className="awards-div">
							<img src="assets/images/award-img1.png" width="0" height="0" alt="" title=""/>
							<h4>Winner</h4>
							<p>Vivamus porttitor nunc varius elementum finibus.</p>
						</div>
					</div>
					<div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1500">
						<div className="awards-div">
							<img src="assets/images/award-img1.png" width="0" height="0" alt="" title=""/>
							<h4>Winner</h4>
							<p>Vivamus porttitor nunc varius elementum finibus.</p>
						</div>
					</div>
					<div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1800">
						<div className="awards-div">
							<img src="assets/images/award-img1.png" width="0" height="0" alt="" title=""/>
							<h4>Winner</h4>
							<p>Vivamus porttitor nunc varius elementum finibus.</p>
						</div>
					</div>
					<div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="2000">
						<div className="awards-div">
							<img src="assets/images/award-img1.png" width="0" height="0" alt="" title=""/>
							<h4>Winner</h4>
							<p>Vivamus porttitor nunc varius elementum finibus.</p>
						</div>
					</div>
					<div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="2000">
						<div className="awards-div">
							<img src="assets/images/award-img1.png" width="0" height="0" alt="" title=""/>
							<h4>Winner</h4>
							<p>Vivamus porttitor nunc varius elementum finibus.</p>
						</div>
					</div> */}
				</div>
			</div>
		</div>
      <Followus />
	  <Footer />
    </React.Fragment>
  )
}

export default Awards
