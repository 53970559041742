import Endpoints from "../Endpoints";
import { baseUrl } from "../ApiHelper";

export const fetchOurTeam = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.ourteam}`);
    return await data.json();
};

export const fetchOurTeamBanner = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.ourteambanner}`);
    return await data.json();
};