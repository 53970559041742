import React from 'react'
import $ from 'jquery';
import "jquery-ui-dist/jquery-ui";
import { useEffect } from 'react';
import Marqueepagee from '../marquee/Marqueepagee'
import Breadcrumbpage from '../commonpage/Breadcrumbpage'
import Followus from '../footer/Followus'
import Footer from "../footer/Footer";
import Headertop from '../header/Headertop';
import Headermain from '../header/Headermain';
import { useState } from 'react';
import { fetchGetStartedHomeStep } from '../../controler/getstarted/getstarted';
import { fetchOurServiceSlider } from '../../controler/ourservices/ourservices';
import { fetchSelectCurrency, fetchTravelCard, fetchTravelCardForm, fetchTravelFeature, fetchTravelFeatureHeading } from '../../controler/home/Home';
import parse from 'html-react-parser';
import { useFormik } from 'formik';
import { travelCardSchema } from '../../schemas';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { imageUrl } from '../../controler/ApiHelper';

const initialValues ={
    currency : "",
    forexAmount : "",
    inrAmount : ""
};

function Travelcard() {
    const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues,
        validationSchema : travelCardSchema,
        onSubmit : async (values, action) => {
            const data = await fetchTravelCardForm(values);
            console.log("Travel card dataaaaaaaaaaaaaaaaaaaaaaaaaaaa", data)
            action.resetForm();
            toast.success("Your data has been recorded")

        }
    })
    console.log("formik Travel Card Form Handling ", errors);

    useEffect(() => {
        $('.flex-slide').hover(function(){	
            $(this).parent().find('.flex-slide.active').removeClass('active');
            $(this).addClass('active');
            },
            function(){	
            $(this).removeClass('active');
        },						 
        );
    })
    //var imageUrl = "http://localhost:8070";
    const[ourserviceslider, setOurServiceSlider] = useState([])
	const[ourservicefaq, setOurServicefaq] = useState([])
    const[getstartedhomestep, setGetStartedHomeStepData] = useState([])
    const[travelfeatureheading, setTravelFeatureHeading] =useState([])
    const[travelfeature, setTravelFeature] = useState([])
    const[travelcard, setTravelCard] = useState([])
    const[active, setActive] = useState(0)
    const[selectcurrency, setSelectCurrency] = useState([])
    useEffect(() => {
        async function selectcurrency () {
            const data = await fetchSelectCurrency ();
            setSelectCurrency(data.data);
            // console.log("currency data", data.data)
        }
        selectcurrency();
 
        async function ourserviceslider () {
			let travelcardfaq = []
			const data = await fetchOurServiceSlider();
			for(let i=0;i<data.data.length;i++){
				if(data.data[i].title === "Travel Card"){
					travelcardfaq.push(data.data[i].faq_category)
				}
			}

			for(let i=0;i<data.data.length;i++){
				if(data.data[i].title ==="Travel Card"){
					setOurServiceSlider(data.data[i]);
				}
			}
			setOurServicefaq(...travelcardfaq);
			// console.log("Travel card faq chnagesssssssssssssssssssss", data.data[0].faq_category,travelcardfaq)
		}
		ourserviceslider();

        async function getstartedhomestep (){
			let travelcardgetstartedcode = []
			const data = await fetchGetStartedHomeStep();
			for(let i=0; i<data.data.length;i++){
				if(data.data[i].pageName === "Travel Card"){
					travelcardgetstartedcode.push(data.data[i])
				}
			}
			setGetStartedHomeStepData(travelcardgetstartedcode);
		// console.log("get Started data", travelcardgetstartedcode);
		}
		getstartedhomestep();

        async function travelfeatureheading(){
            const data = await fetchTravelFeatureHeading()
            setTravelFeatureHeading(data.data[0]);
        }
        travelfeatureheading();

        async function travelfeature (){
            const data = await fetchTravelFeature();
            setTravelFeature(data.data);
        }
        travelfeature();

        async function travelcard (){
            const data = await fetchTravelCard();
            setTravelCard(data.data);
        }
        travelcard();
    },[])
    const handleActive=(val)=>{
		// console.log("valllllll",val);
		setActive(val)
			}
  return (
    <React.Fragment>
        <Headertop />
		<div className="innerbanner-main">
				<Headermain />
				<div id="myCarousel" className="carousel slide" data-ride="carousel">
					<div className="carousel-inner">
						<div className="item active">
							<img src={imageUrl+ourserviceslider.detailBannerImage} width="0" height="0" alt="" title=""/>
							<div className="banner-content hidden-xs">
								<div className="homebannercontent">
									<h1>{ourserviceslider.title}</h1>
									<p>{ourserviceslider.overviewDesc}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        <Marqueepagee />
        <Breadcrumbpage />
        <div className="buforex-main" data-aos="fade-up" data-aos-duration="1500">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12" data-aos="fade-up" data-aos-duration="1500">
                        <ul className="nav nav-tabs">
                            <li><a href="#currencyexchange">
                                <img src="assets/images/form-icon1.png" width="0" height="0" alt="" title=""/> Currency Exchange
                            </a></li>
                            <li><a href="remittance.html">
                                <img src="assets/images/form-icon2.png" width="0" height="0" alt="" title=""/> Remittance</a></li>
                            <li><a href="#sendmoneyaboad">
                                <img src="assets/images/form-icon3.png" width="0" height="0" alt="" title=""/> Send Money Abroad</a></li>
                            <li className="active"><a href="travel-card.html">
                                <img src="assets/images/form-icon3.png" width="0" height="0" alt="" title=""/> Travel Card</a></li>
                            <li><a href="travel-insurance.html">
                                <img src="assets/images/form-icon3.png" width="0" height="0" alt="" title=""/> Travel Insurance</a></li>
                        </ul>
                        </div>
                        <div className="col-sm-12" data-aos="fade-up" data-aos-duration="1500">
                        <div className="tab-content">
                            <div id="forexcard" className="tab-pane fade in active">
                                <div className="row">
                                    <form onSubmit={handleSubmit}>
                                    <div id="Cars2" className="desc">
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>Check Rate</label>
                                                <select className="form-control">
                                                    <option>One Currency Card</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>Currency</label>
                                                <select 
                                                autoComplete='off'
                                                name='currency'
                                                value={values.currency}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                className="form-control">
                                                    <option>Select Currency</option>
                                                    {selectcurrency.map(selectcurrencyyy => (
                                                        <option>{selectcurrencyyy.currency}</option>
                                                    ))}
                                                    
                                                </select>
                                                <span>Rate: 1 USD = Rs.84.29</span>
                                                {errors.currency && touched.currency ? <p className='form-errors'>{errors.currency}</p> : null}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>Forex Amount</label>
                                                <input type="text" 
                                                name='forexAmount' 
                                                className="form-control" 
                                                placeholder="Forex Amount" 
                                                autoComplete='off'
                                                value={values.forexAmount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                />
                                                <img src="assets/images/inr-icon.png" width="0" height="0" alt="" title=""/>
                                                {errors.forexAmount && touched.forexAmount ? <p className="form-errors">{errors.forexAmount}</p> : null}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>INR Amount</label>
                                                <input type="text" 
                                                name='inrAmount'
                                                className="form-control" 
                                                placeholder="Amount in INR"
                                                autoComplete='off'
                                                value={values.inrAmount} 
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                />
                                                <img src="assets/images/inr-icon.png" width="0" height="0" alt="" title=""/>
                                                {errors.inrAmount && touched.inrAmount ? <p className="form-errors">{errors.inrAmount}</p> : null}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 text-right">
                                            {/* <a href="#!" className="btn btn-success"><i className="fal fa-plus-circle"></i> Add Currency/Product</a> */}
                                            <button type='submit' className="btn btn-blue">Buy Now</button>
                                        </div>
                                    </div>
                                    </form>
                                </div>
                                
                                <hr />
                                <div className="row">
                                    <div className="col-sm-offset-2 col-sm-8 currentexchangediv">
                                        <p>Communication Details (You order confirmation details will be sent on these contact details)</p>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <input type="text" className="form-control" placeholder="Mobile No." />
                                            </div>
                                            <div className="col-sm-4">
                                                <input type="text" className="form-control" placeholder="E-Mail Address" />
                                            </div>
                                            <div className="col-sm-4">
                                                <a href="#!" className="btn btn-primary">Get a Quote</a>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="radiobutton">
                                                    <div className="radiobuttonleftother">
                                                        <div className="form__radio-group">
                                                            <input type="radio" name="other" id="other" className="form__radio-input" value="3" />
                                                            <label className="form__label-radio form__radio-label" htmlFor="other">
                                                            <span className="form__radio-button"></span> I accept the <a href="#!">Privacy Policy</a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="travelcardfeature-main" data-aos="fade-up" data-aos-duration="1500">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="flex-container">
                            {travelcard.map(travelcarddd => {
                                return(
                                    <div key={travelcard.indexOf(travelcarddd)} className ={travelcard.indexOf(travelcarddd) === 0? "flex-slide home active" : travelcard.indexOf(travelcarddd) === 1? "flex-slide about" : "flex-slide work"}>
                                    <div className="icon">
                                        <h3> <img src={imageUrl+travelcarddd.icon} alt='' title=''/> {travelcarddd.title}</h3>
                                        <ul className="travelcard-expenddiv">
                                            {parse(travelcarddd.description)}
                                            
                                        </ul>
                                    </div>
                                </div>
                                )
                            })}
                            {/* <div className="flex-slide home active">
                                <div className="icon">
                                    <h3> <img src="assets/images/travelcardfeature-icon1.png" alt='' title=''/> Smart</h3>
                                    <ul>
                                        <li>Load upto eight currencies onto a single card.</li>
                                        <li>Load upto eight currencies onto a single card Load upto eight currencies onto a single card.</li>
                                        <li>Load upto eight currencies onto a single card Load upto eight currenc.</li>
                                        <li>Load upto eight currencies onto a single card Load upto eight currencies onto a single card.</li>
                                        <li>Load upto eight currencies onto a single card.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex-slide about">
                                <div className="icon">					
                                    <h3> <img src="assets/images/travelcardfeature-icon2.png" alt='' title=''/> Secure</h3>
                                    <ul>
                                        <li>Load upto eight currencies onto a single card.</li>
                                        <li>Load upto eight currencies onto a single card Load upto eight currencies onto a single card.</li>
                                        <li>Load upto eight currencies onto a single card Load upto eight currenc.</li>
                                        <li>Load upto eight currencies onto a single card Load upto eight currencies onto a single card.</li>
                                        <li>Load upto eight currencies onto a single card.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex-slide work">
                                <div className="icon">
                                    <h3> <img src="assets/images/travelcardfeature-icon3.png" alt='' title=''/> Convenient</h3>							
                                    <ul>
                                        <li>Load upto eight currencies onto a single card.</li>
                                        <li>Load upto eight currencies onto a single card Load upto eight currencies onto a single card.</li>
                                        <li>Load upto eight currencies onto a single card Load upto eight currenc.</li>
                                        <li>Load upto eight currencies onto a single card Load upto eight currencies onto a single card.</li>
                                        <li>Load upto eight currencies onto a single card.</li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="travelinsurance-main" data-aos="fade-up" data-aos-duration="1500">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h1 data-aos="fade-up" data-aos-duration="1500">Travel Card</h1>
                        <p data-aos="fade-up" data-aos-duration="1500"><b>"Foreign Exchange Prepaid Travel Card Loded With <br />
                            Multiple Currency Options."</b></p>
                        <div className="travelinsurance-contant" data-aos="fade-up" data-aos-duration="1500">
                            <div className="travelinsurance-contantleft" data-aos="fade-up" data-aos-duration="1500">
                                <p>These days whether you are off to holidays or off to a business trip, one of the most the essential parts 
                                    of your trip is managing foreign exchange. While you can avail benefit from our Foreign Exchange.
                                </p>
                                <p>In case any one plans to visit multiple countries, then it only makes sense to opt for
                                    a forex travel card as it eliminates the hassle of carrying separate foreign currency of
                                    each country.
                                </p>
                            </div>
                            <div className="travelinsurance-contantright" data-aos="fade-down" data-aos-duration="1500">
                                <img src="assets/images/forex-cardatm-img.jpg" width="0" height="0" alt="" title=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <div className="ourmulticurrency-main" data-aos="fade-up" data-aos-duration="1500">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h3 data-aos="fade-up" data-aos-duration="1500">{ourserviceslider.title}</h3>
                        <div className="card-div">
                            <div className="card-leftdiv" data-aos="fade-up" data-aos-duration="1500">
                                <img src={imageUrl+ourserviceslider.detailImage} width="0" height="0" alt="" title=""/>
                            </div>
                            <div className="card-rightdiv" data-aos="fade-up" data-aos-duration="1500">
                                <p>{ourserviceslider.detailDesc} </p>
                            </div>
                        </div>
                        <div className="cardfeature-div" data-aos="fade-up" data-aos-duration="1500">
                            <h4>{travelfeatureheading.title}</h4>
                            <ul>
                                {travelfeature.map(travelfeatureee => {
                                    return(
                                        <li key={travelfeature.indexOf(travelfeatureee)}>{travelfeatureee.text}</li>
                                    )
                                })}
                                {/* <li>One ATM PIN and internet login to manage all the curriences on your card.</li>
                                <li>Reload and use the card for upto 3 years.</li>
                                <li>Highest level of security with embedded chip.</li>
                                <li>Facility to encash the balance on your card.</li>
                                <li>Instant transaction alert through email/SMS.</li>
                                <li>NFC which enables contactless payment.</li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="getstartednew-main aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h3>Get Started</h3>
                        <ul>
                        {getstartedhomestep.map(getstartedhomesteppp => {
								return(
									<li key={getstartedhomestep.indexOf(getstartedhomesteppp)}>
										<img src={imageUrl+getstartedhomesteppp.icon} width="0" height="0" alt="" title=""/>
										<h4>{getstartedhomesteppp.text}</h4>
										<p>{getstartedhomesteppp.desc}</p>
									</li>
								)
							})}
                            {/* <li>
                                <img src="assets/images/getstarted-icon1.png" width="0" height="0" alt="" title=""/>
                                <h4>STEP-1</h4>
                                <p>Select currency and denomination you need to carry along.</p>
                            </li>
                            <li>
                                <img src="assets/images/getstarted-icon2.png" width="0" height="0" alt="" title=""/>
                                <h4>STEP-2</h4>
                                <p>Enter traveller details & delivery option doorstep delivery or brancg pick up.</p>
                            </li>
                            <li>
                                <img src="assets/images/getstarted-icon3.png" width="0" height="0" alt="" title=""/>
                                <h4>STEP-3</h4>
                                <p>Receipt of forex  order completion at  blocked rate</p>
                            </li>
                            <li>
                                <img src="assets/images/getstarted-icon3.png" width="0" height="0" alt="" title=""/>
                                <h4>STEP-4</h4>
                                <p>Receipt of forex  order completion at  blocked rate</p>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="faq-main aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
            <div className="container">
                <div className="row">
                    <div className="col-lg-offset-2 col-lg-8">
                        <h3 data-aos="fade-up" data-aos-duration="1500">FAQ's</h3>
                        <div className="panel-group" id="accordion1" data-aos="fade-up" data-aos-duration="1500">
                            {ourservicefaq.map(faq => {
                            return(
                            <div className="panel">
                                <div className="panel-heading" onClick={() => handleActive(ourservicefaq.indexOf(faq))} >
                                <h4 className="panel-title">
                                        <a className={ourservicefaq.indexOf(faq) === active ? "accordion-toggle1" : "accordion-toggle1 collapsed"} data-toggle="collapse" 
                                        data-parent="#accordion1 " href="#panelBody1">{faq.title}</a>
                                </h4>
                                </div>
                                <div id={ourservicefaq.indexOf(faq)} className={ourservicefaq.indexOf(faq) === active ? "panel-collapse collapse in" : "panel-collapse collapse"}>
                                    <div className="panel-body">
                                    <p>{faq.description}
                                    </p>
                                    </div>
                            </div>
                        </div>
					)})}
                            {/* <div className="panel">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle1" data-toggle="collapse" data-parent="#accordion1 " href="#panelBody1">Nam imperdiet sem in fringilla placerat.</a>
                                    </h4>
                                </div>
                                <div id="panelBody1" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                            the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                            handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle1 collapsed" data-toggle="collapse" data-parent="#accordion1" href="#panelBody2">
                                            Integer sit amet odio id enim hendrerit volutpat vel sed dolor.
                                        </a>
                                    </h4>
                                </div>
                                <div id="panelBody2" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                                    the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                                    handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle1 collapsed" data-toggle="collapse" data-parent="#accordion1" href="#panelBody3">
                                            Integer sit amet odio id enim hendrerit volutpat vel sed dolor.
                                        </a>
                                    </h4>
                                </div>
                                <div id="panelBody3" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                                    the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                                    handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle1 collapsed" data-toggle="collapse" data-parent="#accordion1" href="#panelBody4">
                                            Integer sit amet odio id enim hendrerit volutpat vel sed dolor.
                                        </a>
                                    </h4>
                                </div>
                                <div id="panelBody4" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                                    the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                                    handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Followus />
        <Footer />
        <ToastContainer />
    </React.Fragment>
  )
}

export default Travelcard
