import Endpoints from "../Endpoints";
import { baseUrl } from "../ApiHelper";

export const fetchBlogBanner = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.blogbanner}`);
    return await data.json();
};

export const fetchBlogGrid = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.bloggrid}`);
    return await data.json();
};