import React from "react";
import "./formmainpage.css";
import {useFormik } from "formik";
import { signUpSchema } from "../../schemas";

const initialValues ={
    name : "",
    email: "",
    password :"",
    confirm_password : "",
};

function Formmainpage() {
const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik ({
        initialValues,
        validationSchema : signUpSchema,
        onSubmit : (values, action) => {
            // console.log("formik Form Handling ", values);
            action.resetForm();
        }
        
    })
    console.log("formik Form Handling ", errors);
  return (
    <>
      <div className="container commonformclass">
        <div className="row">
          <form onSubmit={handleSubmit}>
            <div className="col-sm-12">
              <h1>Multipal Filed In a One Form</h1>
            </div>
            <div className="col-sm-4">
              <label htmlFor="Name">Name</label>
              <input
                type="text"
                name="name"
                autoComplete="off"
                className="form-control style"
                placeholder="Name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.name ? <p className="form-errors">{errors.name}</p> : null}
            </div>
            <div className="col-sm-4">
              <label htmlFor="Email">Email Id</label>
              <input 
              type="text" 
              name="email"
              autoComplete="off"
              className="form-control"
              placeholder="Email" 
              value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
               {errors.email && touched.email ? <p className="form-errors">{errors.email}</p> : null}
            </div>
            <div className="col-sm-4">
              <label htmlFor="Password">Password</label>
              <input
                type="text"
                name="password"
                autoComplete="off"
                className="form-control"
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.password && touched.password ? <p className="form-errors">{errors.password}</p> : null}
            </div>
            <div className="col-sm-4">
              <label htmlFor="Confirm Password">Confirm Password</label>
              <input
                type="text"
                name="confirm_password"
                autoComplete="off"
                className="form-control"
                placeholder="Comfirm Password"
                value={values.confirm_password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.confirm_password && touched.confirm_password ? <p className="form-errors">{errors.confirm_password}</p> : null}
            </div>
            {/* <div className='col-sm-4'>
                <input type='text' className='form-control' placeholder='Email Id'/>
            </div>
            <div className='col-sm-4'>
                <input type='text' className='form-control' placeholder='Mobile No.'/>
            </div>
            <div className='col-sm-4'>
                <input type='text' className='form-control' placeholder='Password'/>
            </div>
            <div className='col-sm-4'>
                <input type='text' className='form-control' placeholder='Confirm Password'/>
            </div> */}
            {/* <div className='col-sm-4'>
                <select className='form-control'>
                    <option>Select Option</option>
                    <option>Sourabh</option>
                    <option>Meenakshi</option>
                    <option>Deepshikha</option>
                    <option>Ashwarya</option>
                </select>
            </div>
            <div className='col-sm-4'>
                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                <label htmlFor="vehicle1"> I have a bike</label>
            </div>
            <div className='col-sm-4'>
                <input type="radio" id="huey" name="drone" value="huey" />
                <label htmlFor="huey">Huey</label>
            </div>
            <div className='col-sm-4'>
                <input type="file" id="myFile" name="filename" />
            </div>
            <div className='col-sm-12'>
                <textarea className='form-control' style={{height: '150px'}} placeholder='Write here...'></textarea>
            </div> */}
            <div className="col-sm-12">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Formmainpage;
