import React from "react";
// import { toast,ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import * as yup from 'yup';
import { useFormik } from "formik";
import { contactFormSchema } from "../../schemas";
import { fetchContactForm } from "../../controler/contact/contact";

const initialValues = {
  name: "",
  email: "",
  phone: "",
  comment: "",
};

function Contactform() {
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema: contactFormSchema,
    onSubmit: async(values, action) => {
      // console.log("contcat form validation ", values);
      const data = await fetchContactForm(values);
      console.log("contcat form validation ", data);
      action.resetForm();
    }
  })

  // const[writetous, setWriteToUs] = useState({
  // 	username :"",
  // 	email :"",
  // 	phone :"",
  //     comment:"",
  // });
  // const [record, setRecord] = useState([]);
  // const[nameError, setNameError] = useState(false);
  // const handleInput = (e) => {
  // 	console.log("userrrrrrrrrr",e.target.name,e.target.value ,e.target.value.length);
  // 	const name = e.target.name;
  // 	const value = e.target.value;
  // 	if(e.target.value.length < 4)
  // 	{
  // 		setNameError(true)
  // 	} else(
  // 		setNameError(false)
  // 	)
  // 	setWriteToUs({ ...writetous, [name] : value});
  // };

  // async function handleSubmit(e){
  // 	e.preventDefault();

  // 	const newRecord = { ...writetous}
  //     const data = await fetchContactForm(record);
  // 	setRecord([...record, newRecord])
  //     console.log(newRecord,data)
  //     toast.success("Thanks y")
  // }

  // useEffect(() => {
  //     async function contactform () {
  //         const data = await fetchContactForm();
  //         setContactForm(data.data);
  //         console.log("contact form here" , data.data);
  //     }
  //     contactform();
  //     // const data = await fetchContactForm();
  // }, [])
  //    async function handleSubmit(){
  //     const data = await fetchContactForm();
  //     setContactForm(data.data);
  //     console.log("contact form here" , data.data);

  //     }
  return (
    <>
      <div className="writetous" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="row">
            <div className="col-lg-offset-2 col-lg-8 col-md-12">
              <h3 data-aos="fade-up" data-aos-duration="1500">
                Write to Us
              </h3>
              <div className="row">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <input
                          type="text"
                          autoComplete="off"
                          name="name"
                          className="form-control"
                          placeholder="Full Name"
                          value={values.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {errors.name && touched.name ? <p>{errors.name}</p> : null}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <input
                          type="number"
                          autoComplete="off"
                          name="phone"
                          className="form-control"
                          placeholder="Mobile No."
                          value={values.phone}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {errors.phone && touched.phone ? <p>{errors.phone}</p> : null}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <input
                          type="text"
                          autoComplete="off"
                          name="email"
                          className="form-control"
                          placeholder="E-mail Address"
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {errors.email && touched.email ? <p>{errors.email}</p> : null}
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          name="comment"
                          style={{ height: "150px" }}
                          placeholder="Write Your Comments"
                          value={values.comment}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        ></textarea>
                        {errors.comment && touched.comment ? <p>{errors.comment}</p> : null}
                      </div>
                    </div>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </>
  );
}

export default Contactform;
