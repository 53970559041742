import React from 'react'
import { useFormik } from 'formik';
import { enquiryFormSchema } from '../../schemas';
import { fetchEnquiryForm } from '../../controler/enquiry/enquiry';


const initialValues = {
    name: "",
    email: "",
    phone: "",
    purpose: "",
    comment: "",
};

const Enquiry = ({ setShowModal, showModal }) => {

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues,
        validationSchema: enquiryFormSchema,
        onSubmit: async (values, action) => {
            // console.log("contcat form validation ", values);
            const data = await fetchEnquiryForm(values);
            console.log("Enquiry form validation", data);

            action.resetForm();
            setShowModal(!showModal)
        }
        // onSubmit : (values, action) => {
        //     console.log("Enquiry Form Handling ", values);
        //     action.resetForm();
        // }
    })
    return (
        <div className="form-section enquiryform" data-aos="fade-down">
            {/* <button onClick={()=>setShowModal(!showModal)}>kkkk</button> */}
            <div className='row'>
                <div className='col-sm-12'>
                    <h1>Get a call back from advisor</h1>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='col-sm-6'>
                        <div className="form-group">
                            <input
                                type="text"
                                autoComplete="off"
                                name="name"
                                className="form-control"
                                placeholder="Full Name"
                                value={values.name}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.name && touched.name ? <p className="form-errors">{errors.name}</p> : null}
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className="form-group">
                            <input
                                type="text"
                                autoComplete="off"
                                name="email"
                                className="form-control"
                                placeholder="E-mail Address"
                                value={values.email}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.email && touched.email ? <p className="form-errors">{errors.email}</p> : null}
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className="form-group">
                            <input
                                type="text"
                                autoComplete="off"
                                name="phone"
                                maxLength={10}
                                className="form-control"
                                placeholder="Mobile No."
                                value={values.phone}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.phone && touched.phone ? <p className="form-errors">{errors.phone}</p> : null}
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <select
                            className='form-control'
                            autoComplete='off'
                            name='purpose'
                            value={values.purpose}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        >
                            <option>Select Purpose</option>
                            <option>Buy FC Notes from QFX</option>
                            <option>Sell FC Notes to QFX</option>
                            <option>Outward Remittance</option>
                            <option>Travel Insurance</option>
                            <option>FX Travel Card</option>
                        </select>
                        {errors.purpose && touched.purpose ? <p className="form-errors">{errors.purpose}</p> : null}

                        {/* <select 
                                                autoComplete='off'
                                                name='currency'
                                                value={values.currency}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                className="form-control">
                                                    <option>Select Currency</option>
                                                    {selectcurrency.map(selectcurrencyyy => (
                                                        <option>{selectcurrencyyy.currency}</option>
                                                    ))}
                                                    
                                                </select> */}
                        {/* <div className="form-group">
                            <input
                                type="text"
                                autoComplete="off"
                                name="purpose"
                                className="form-control"
                                placeholder="Purpose"
                                value={values.purpose}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.purpose && touched.purpose ? <p className="form-errors">{errors.purpose}</p> : null}
                        </div> */}
                    </div>
                    <div className='col-sm-12'>
                        <div className="form-group">
                            <textarea
                                className="form-control"
                                name="comment"
                                style={{ height: "100px" }}
                                placeholder="Share your other queries or comments"
                                value={values.comment}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            ></textarea>
                            {errors.comment && touched.comment ? <p className="form-errors">{errors.comment}</p> : null}
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <button type="submit" className="btn btn-blue">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
            {/* {ShowModal&&
			<div id="thankyoutmodal" className="modal fade thankyoumodal" role="dialog">
                {console.log("helloo")}
				<div className="modal-dialog">

					<div className="modal-content">
						<div className="modal-header">
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						</div>
						<div className="modal-body">
							<img src='assets/images/logo.png' />
							<h2>Thank You for submiting Form!</h2>
							<p>We will Back you call soon....</p>
						</div>
					</div>

				</div>
			</div>
} */}
        </div>
    )
}

export default Enquiry