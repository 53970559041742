import * as Yup from 'yup';

export const signUpSchema = Yup.object({
    name : Yup.string().min(2).max(25).required("Please Enter Your Name"),
    email : Yup.string().email().required("Please Enter Your Email"),
    password : Yup.string().min(6).required("Please Enter Your Password"),
    confirm_password: Yup.string().required().oneOf([Yup.ref("password"), null], "Password Must match"),
})

export const SubscribeSchema = Yup.object({
    fullname : Yup.string().min(2).max(25).required("Please Enetr Your Name"),
    email : Yup.string().email().required("Please Enter Your Email"),
})

export const contactFormSchema = Yup.object({
    name : Yup.string().min(2).max(25).required("Please Enter Your Name"),
    email : Yup.string().email().required("Please Enter Your Email"),
    phone : Yup.string().matches(/^[0-9]{10}$/, 'Mobile number must be a 10-digit numeric value')
    .required('Mobile number is required'),
    comment: Yup.string().required("Please add some comment here"),
})

export const travelCardSchema = Yup.object({
    // checkRate : Yup.string().min(2).max(25).required("Please Enter Your Name"),
    currency : Yup.string().required("Please Select an Currancy option"),
    forexAmount : Yup.number().required('Please Enter a Valid Forex Amount'),
    inrAmount: Yup.number().required('Please Enter a Valid INR Amount'),
})

export const remittanceFormSchema = Yup.object({
    fullname : Yup.string().min(2).max(25).required("Please Enter Your Name"),
    email : Yup.string().email().required("Please Enter Your Email"),
    phone : Yup.string().matches(/^[0-9]{10}$/, 'Mobile number must be a 10-digit numeric value')
    .required('Mobile number is required'),
    location : Yup.string().required("Please Select a location"),
    currency : Yup.string().required("Please Select an Currancy option"),
    forexAmount : Yup.number().required('Please Enter a Valid Forex Amount'),
    inrAmount: Yup.number().required('Please Enter a Valid INR Amount'),
})

export const sendMoneyAbroadFormSchema = Yup.object({
    // checkRate : Yup.string().min(2).max(25).required("Please Enter Your Name"),
    currency : Yup.string().required("Please Select an Currancy option"),
    forexAmount : Yup.number().required('Please Enter a Valid Forex Amount'),
    inrAmount: Yup.number().required('Please Enter a Valid INR Amount'),
})


export const enquiryFormSchema = Yup.object({
    name : Yup.string().min(2).max(25).required("Please Enter Your Name"),
    email : Yup.string().email().required("Please Enter Your Email"),
    phone : Yup.string().matches(/^[0-9]{10}$/, 'Mobile no. must be 10-digit')
    .required('Mobile Number is required'),
    purpose : Yup.string().min(2).max(25).required("Please Enter Your Purpose"),
    comment: Yup.string().required("Please add some comment here"),
})