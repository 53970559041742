import Endpoints from "../Endpoints";
import { baseUrl } from "../ApiHelper";

export const fetchBanner = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.banner}`);
    return await data.json();
};

export const fetchHome = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.home}`);
    // console.log("eati homedata",data)
    return await data.json();
};

export const fetchWhyQuickForex = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.whyquickforex}`);
    return await data.json();
};

export const fetchWhyQuickForexIcon = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.whyquickforexicon}`);
    return await data.json();
};

export const fetchTravelFeatureHeading = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.travelfeatureheading}`);
    return await data.json();
};

export const fetchTravelFeature = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.travelfeature}`);
    return await data.json();
};

export const fetchTravelCard = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.travelcard}`);
    return await data.json();
};

// export const fetchTravelCardForm = async () => {
//     const data = await fetch(`${baseUrl}/${Endpoints.travelcardform}`);
//     return await data.json();
// };

export const fetchTravelCardForm = async (jsonData) => {
    // console.log(".......................",jsonData);
    const data = await fetch(`${baseUrl}/${Endpoints.travelcardform}`, {
    method: 'POST', 
    headers: {
        'Content-Type': 'application/json'
      },
    mode: 'cors', 
    body: JSON.stringify(jsonData)

  });
//   console.log("..................22222222",data.json);

    return await data.json();
};

export const fetchSendMoneyAbroadForm = async (jsonData) => {
    // console.log(".......................",jsonData);
    const data = await fetch(`${baseUrl}/${Endpoints.sendmoneyabroadform}`, {
    method: 'POST', 
    headers: {
        'Content-Type': 'application/json'
      },
    mode: 'cors', 
    body: JSON.stringify(jsonData)

  });
//   console.log("..................ssssssssssssssssssss",data.json);

    return await data.json();
};

export const fetchTravelInsuranceForm = async (jsonData) => {
    // console.log(".......................",jsonData);
    const data = await fetch(`${baseUrl}/${Endpoints.travelinsuranceform}`, {
    method: 'POST', 
    headers: {
        'Content-Type': 'application/json'
      },
    mode: 'cors', 
    body: JSON.stringify(jsonData)

  });
//   console.log("..................ssssssssssssssssssss",data.json);

    return await data.json();
};


export const fetchSelectCurrency = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.selectcurrency}`);
    return await data.json();
};







// export const fetchNewsLetterForm = async (body) => {
//     const data = await fetch(`${baseUrl}/${Endpoints.newsletterform}/${body}`);
//     return await data.json();
// };



