import Endpoints from "../Endpoints";
import { baseUrl } from "../ApiHelper";

export const fetchGetStartedHome = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.getstartedhome}`);
    return await data.json();
};

export const fetchGetStartedHomeStep = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.getstartedhomestep}`);
    return await data.json();
};