import React from "react"

function Ournetwork() {
    return (
        <div className="ournetwork-main jobwarp" data-aos="fade-up" data-aos-duration="1500">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="ournetwork-div">
                            <div className="ournetwork-leftdiv" data-aos="fade-up" data-aos-duration="1500">
                                <h3>Our Network</h3>
                                <div id="accordion" className="panel-group">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">
                                                <a href="#panelBodyFour" className="accordion-toggle" data-related-map="map1" data-toggle="collapse" data-parent="#accordion">
                                                    Ahmedabad
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="panelBodyFour" className="panel-collapse collapse in">
                                            <div className="panel-body">
                                                <p>Shop No. 226 & 229, Second Floor, Super Mall, Near Lal Bangla, C.G. Road, NavrangPura, Ahmedabad-380009</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">
                                                <a href="#panelBodyEleven" className="accordion-toggle collapsed" data-related-map="map2" data-toggle="collapse" data-parent="#accordion">
                                                    Chandigarh
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="panelBodyEleven" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Suit No. 105, 1st Floor, SCO 169-170, Sector 8C, Chandigarh-160009</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">
                                                <a href="#panelBodyNine" className="accordion-toggle collapsed" data-related-map="map3" data-toggle="collapse" data-parent="#accordion">
                                                    Connaught Place
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="panelBodyNine" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Flat No. 110, 1st Floor, Arunachal Building, 19-Barakhamba Road, New Delhi-110001</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">
                                                <a href="#panelBodyEight" className="accordion-toggle collapsed" data-related-map="map4" data-toggle="collapse" data-parent="#accordion">
                                                    Jalandhar
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="panelBodyEight" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>101, Lower Ground Floor, Palm Rose World Trade Centre, Near Bus Stand, Jalandhar-144001</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">
                                                <a href="#panelBodyOne" className="accordion-toggle collapsed" data-related-map="map5" data-toggle="collapse" data-parent="#accordion">
                                                    Karol Bagh Head Office
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="panelBodyOne" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>3507/6 , Kirti Sadan Building, Pyare Lal Road, Bank Street, Karol Bagh, New Delhi-110005</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">
                                                <a href="#panelBodyTwo" className="accordion-toggle collapsed" data-related-map="map6" data-toggle="collapse" data-parent="#accordion">
                                                    Kolkata
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="panelBodyTwo" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>502A, Vasundhara Building, 5th Floor, 2/7, Sarat Bose Road, Kolkata- 700020</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">
                                                <a href="#panelBodyThree" className="accordion-toggle collapsed" data-related-map="map7" data-toggle="collapse" data-parent="#accordion">
                                                    Ludhiana
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="panelBodyThree" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>B-20, 2692, 1st Floor, Gurudev Nagar, Ferozepur Road, Above Goyal hyundai Showroom, Ludhiana, Punjab -141002</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">
                                                <a href="#panelBodyFive" className="accordion-toggle collapsed" data-related-map="map8" data-toggle="collapse" data-parent="#accordion">
                                                    Lucknow
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="panelBodyFive" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Office No. 207, Second Floor,Building No. 126/31, Shalimar Square, B.N. Road Lal Bagh, Lucknow, Uttar Pradesh-226001</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">
                                                <a href="#panelBodySeven" className="accordion-toggle collapsed" data-related-map="map9" data-toggle="collapse" data-parent="#accordion">
                                                    Noida
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="panelBodySeven" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Logix City Centre 1 , Unit No. 1007, Sector 32, Noida, Gautam Budh Nagar, Uttar Pradesh-201301</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">
                                                <a href="#panelBodyTen" className="accordion-toggle collapsed" data-related-map="map10" data-toggle="collapse" data-parent="#accordion">
                                                    Surat
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="panelBodyTen" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>Space No. G-211, Ground Floor, Shiv Shakti Shopping Centre, Ram Chowk, Ghod-Dhod Road, Surat-395007</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">
                                                <a href="#panelBodySix" className="accordion-toggle collapsed" data-related-map="map11" data-toggle="collapse" data-parent="#accordion">
                                                    Vadodara
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="panelBodySix" className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>GF-6, Race Course Tower, Near Natubhai Circle, Opp. Citi Bank, Vadodara -390007</p>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            {/* <div className="ournetwork-rightdiv" data-aos="fade-down" data-aos-duration="1500">
                                <img src="assets/images/Ahmedabad.png" className="map1 mapmainclass" width="0" height="0" alt="" title="" />
                                <img src="assets/images/Chandigarh.png" className="map2 mapmainclass" width="0" height="0" alt="" title="" />
                                <img src="assets/images/Connaught-place.png" className="map3 mapmainclass" width="0" height="0" alt="" title="" />
                                <img src="assets/images/Jalandhar.png" className="map4 mapmainclass" width="0" height="0" alt="" title="" />
                                <img src="assets/images/Karol-Bagh.png" className="map5 mapmainclass" width="0" height="0" alt="" title="" />
                                <img src="assets/images/Kolkata.png" className="map6 mapmainclass" width="0" height="0" alt="" title="" />
                                <img src="assets/images/Ludhiana.png" className="map7 mapmainclass" width="0" height="0" alt="" title="" />
                                <img src="assets/images/Lucknow.png" className="map8 mapmainclass" width="0" height="0" alt="" title="" />
                                <img src="assets/images/Noida.png" className="map9 mapmainclass" width="0" height="0" alt="" title="" />
                                <img src="assets/images/Surat.png" className="map10 mapmainclass" width="0" height="0" alt="" title="" />
                                <img src="assets/images/Vadodara.png" className="map11 mapmainclass" width="0" height="0" alt="" title="" />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Ournetwork