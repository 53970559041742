import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import Ournetwork from '../Ournetwork';
import Footer from "../footer/Footer";
import Followus from "../footer/Followus";
// import {
//   fetchAboutPage,
//   fetchAwardList,
//   fetchJourney,
//   fetchOurTeam,
//   fetchWhyQuickForex,
//   fetchWhyQuickForexIcon,
// } from "../../controler/home/Home";
import Marqueepagee from "../marquee/Marqueepagee";
import Headertop from "../header/Headertop";
import Headermain from "../header/Headermain";
import { fetchAboutPage, fetchJourney } from "../../controler/about/about";
import {
  fetchWhyQuickForex,
  fetchWhyQuickForexIcon,
} from "../../controler/home/Home";
import { fetchOurTeam } from "../../controler/ourteam/ourteam";
import { fetchAwardList } from "../../controler/award/award";
import { imageUrl } from "../../controler/ApiHelper";

function About() {
  // var url = "http://localhost:8070";
  const [aboutpage, setAboutPage] = useState([]);
  const [whyquickData, setWhyQuickData] = useState([]);
  const [whyquickiconData, setWhyQuickIconData] = useState([]);
  const [ourteam, setOurTeam] = useState([]);
  const [awardlist, setAwardList] = useState([]);
  const [journey, setJourney] = useState([]);
  useEffect(() => {
    async function getAboutPage() {
      const data = await fetchAboutPage();
      setAboutPage(data.data[0]);
    }
    getAboutPage();

    async function whyquickforexhomepageData() {
      const data = await fetchWhyQuickForex();
      setWhyQuickData(data.data);
      console.log("222222222222", data.data);
    }
    whyquickforexhomepageData();

    async function whyquickforexiconhomepageData() {
      const data = await fetchWhyQuickForexIcon();
      setWhyQuickIconData(data.data);
    }
    whyquickforexiconhomepageData();

    async function ourteam() {
      const data = await fetchOurTeam();
      setOurTeam(data.data);
    }
    ourteam();

    async function awardlist() {
      const data = await fetchAwardList();
      setAwardList(data.data);
    }
    awardlist();
    async function journey() {
      const data = await fetchJourney();
      setJourney(data.data);
    }
    journey();
  }, []);
  return (
    <React.Fragment>
      <Headertop />
      <div className="innerbanner-main">
        <Headermain />
        <div id="myCarousel" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="item active">
              <img
                src="assets/images/ourservices-banner.jpg"
                width="0"
                height="0"
                alt=""
                title=""
              />
              <div className="banner-content hidden-xs">
                <div className="homebannercontent">
                  <h1>About Us</h1>
                  <p>
                    maecenas suscipit tortor bibendum libero feugiat elementum.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Marqueepagee />
      <div
        className="breadcrumb-main"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              <li className="breadcrumb-item active">About Us</li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="about-main" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h3 data-aos="fade-up" data-aos-duration="1500">
                {aboutpage.title}
              </h3>
              <p data-aos="fade-up" data-aos-duration="1500">
                {aboutpage.heading}
              </p>

              <div className="overvire-div">
                <div
                  className="overvire-leftdiv"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <p>{aboutpage.description}</p>
                </div>
                <div
                  className="overvire-rightdiv"
                  data-aos="fade-down"
                  data-aos-duration="1500"
                >
                  <img
                    src={imageUrl + aboutpage.image}
                    width="0"
                    height="0"
                    alt=""
                    title=""
                  />
                </div>
              </div>
              <h6 data-aos="fade-up" data-aos-duration="1500">
                {aboutpage.subdesc}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div
        className="visionmission-main"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <ul>
                <li data-aos="fade-up" data-aos-duration="1000">
                  <img
                    src={imageUrl + aboutpage.mission_icon}
                    width="0"
                    height="0"
                    alt=""
                    title=""
                  />
                  <h4>{aboutpage.mission_title}</h4>
                  <p>{aboutpage.mission_description}</p>
                </li>
                <li data-aos="fade-up" data-aos-duration="2000">
                  <img
                    src={imageUrl + aboutpage.vision_icon}
                    width="0"
                    height="0"
                    alt=""
                    title=""
                  />
                  <h4>{aboutpage.vision_title}</h4>
                  <p>{aboutpage.vision_description}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className="whyquickforexother-main"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {whyquickData.map((whyquickData) => (
                <>
                  <h3 data-aos="fade-up" data-aos-duration="1500">
                    {whyquickData.title}
                  </h3>
                  <p data-aos="fade-up" data-aos-duration="1500">
                    {whyquickData.quotetitle}
                  </p>
                </>
              ))}
              <OwlCarousel
                id="whyquickforex"
                className="offers owl-theme"
                items={3}
                loop
                nav
                dots={false}
                margin={8}
                autoplay={true}
              >
                {whyquickiconData.map((whyquickiconData) => {
                  return (
                    <div className="item">
                      <div className="whyquickforexother-div">
                        <img
                          src={imageUrl + whyquickiconData.icon}
                          width="0"
                          height="0"
                          alt=""
                          title=""
                        />
                        <h4>{whyquickiconData.icontitle}</h4>
                        <p>{whyquickiconData.description}</p>
                      </div>
                    </div>
                  );
                })}
                {/* <div className="item">
								<div className="whyquickforexother-div">
									<img src="assets/images/whyquickforexother-icon1.png" width="0" height="0" alt="" title=""/>
									<h4>Safe and Security</h4>
									<p>Morbi rhoncus mauris at venenatis varius.</p>
								</div>
							</div>
							<div className="item">
								<div className="whyquickforexother-div">
									<img src="assets/images/whyquickforexother-icon2.png" width="0" height="0" alt="" title=""/>
									<h4>Instant Exchange</h4>
									<p>Sed tempor dolor non convallis iaculis.</p>
								</div>
							</div> 
							<div className="item">
								<div className="whyquickforexother-div">
									<img src="assets/images/whyquickforexother-icon3.png" width="0" height="0" alt="" title=""/>
									<h4>Experts Support</h4>
									<p>Quisque ac elit nec sapien eleifend feugiat.</p>
								</div>
							</div>
							<div className="item">
								<div className="whyquickforexother-div">
									<img src="assets/images/whyquickforexother-icon4.png" width="0" height="0" alt="" title=""/>
									<h4>Doorstep Delivery</h4>
									<p>Nullam pulvinar purus non molestie efficitur.</p>
								</div>
							</div>
							<div className="item">
								<div className="whyquickforexother-div">
									<img src="assets/images/whyquickforexother-icon3.png" width="0" height="0" alt="" title=""/>
									<h4>Experts Support</h4>
									<p>Quisque ac elit nec sapien eleifend feugiat.</p>
								</div>
							</div>
							<div className="item">
								<div className="whyquickforexother-div">
									<img src="assets/images/whyquickforexother-icon4.png" width="0" height="0" alt="" title=""/>
									<h4>Doorstep Delivery</h4>
									<p>Nullam pulvinar purus non molestie efficitur.</p>
								</div>
							</div> */}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
      <div
        className="ourjourney-main"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h3>Our Journey</h3>
              <OwlCarousel
                id="ourjourney"
                className="offers owl-theme"
                items={3}
                nav
                dots={false}
                margin={8}
                autoplay={true}
              >
                {journey.map((journeyadd) => {
                  return (
                    <>
                      {journey.indexOf(journeyadd) % 2 === 0 ? (
                        <div className="item">
                          <div className="timestamp">
                            <span className="date">
                              <img
                                src={imageUrl + journeyadd.image}
                                width="0"
                                height="0"
                                alt=""
                                title=""
                              />
                              <p>{journeyadd.description}</p>
                            </span>
                          </div>
                          <div className="status">
                            <span>{journeyadd.year}</span>
                          </div>
                        </div>
                      ) : (
                        <div className="item">
                          <div className="status secondteb">
                            <span>{journeyadd.year}</span>
                          </div>
                          <div className="timestamp">
                            <span className="date">
                              <img
                                src={imageUrl + journeyadd.image}
                                width="0"
                                height="0"
                                alt=""
                                title=""
                              />
                              <p>{journeyadd.description}</p>
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}

                {/* 
					<div className="item">
						<div className="timestamp">
							<span className="date">
							   <img
								  src="assets/images/buysell-img1.jpg" width="0" height="0" alt="" title="" />
							   <p>Max India concluded a mega corporate restructuring demerging into 3 holding companies – Max Financial 
								Services Limited (Life Insurance).</p>
								  </span>
						 </div>
						 <div className="status">
							<span>2014</span>
						 </div>
					</div>
					<div className="item">
						<div className="status secondteb">
							<span> 2015 </span>
						 </div>
						 <div className="timestamp">
							<span className="date">
							   <img
								  src="assets/images/buysell-img1.jpg" width="0" height="0" alt="" title="" />
								Commissioned India’s first enzymatic drug intermediate plant for manufacturing 7ADCA.
								  </span>
						 </div>						 
					</div>
					<div className="item">
						<div className="timestamp">
							<span className="date">
							   <img
								  src="assets/images/buysell-img1.jpg" width="0" height="0" alt="" title="" />
							   <p>Max India concluded a mega corporate restructuring demerging into 3 holding companies – Max Financial 
								Services Limited (Life Insurance).</p>
								  </span>
						 </div>
						 <div className="status">
							<span>2014</span>
						 </div>
					</div>
					<div className="item">
						<div className="status secondteb">
							<span> 2013 </span>
						 </div>
						 <div className="timestamp">
							<span className="date">
							   <img
								  src="assets/images/buysell-img1.jpg" width="0" height="0" alt="" title="" />
								Commissioned India’s first enzymatic drug intermediate plant for manufacturing 7ADCA. This plant also 
								received USFDA approvals.
								  </span>
						 </div>
					</div>
					<div className="item">
						<div className="timestamp">
							<span className="date">
							   <img
								  src="assets/images/buysell-img1.jpg" width="0" height="0" alt="" title=""/>
							   <p>Max India concluded a mega corporate restructuring demerging into 3 holding companies  Max Financial 
								Services Limited (Life Insurance), Max India Limited .</p>
								  </span>
						 </div>
						 <div className="status">
							<span>2012</span>
						 </div>
					</div>  */}
              </OwlCarousel>
              {/* <div id="ourjourney" className="offers">
					
				</div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="ourteam-main">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h3 data-aos="fade-up" data-aos-duration="1500">
                Our Team
              </h3>
              <OwlCarousel
                id="ourteam"
                className="offers owl-theme"
                items={4}
                // loop
                nav={true}
                dots={false}
                margin={20}
                autoplay={true}
              >
                {ourteam.map((ourteam) => {
                  return (
                    <div className="item">
                      <div className="ourteam-div">
                        <img
                          src={imageUrl + ourteam.teamImage}
                          width="0"
                          height="0"
                          alt=""
                          title=""
                        />
                        <h4>{ourteam.title}</h4>
                        <p>{ourteam.designation} </p>
                      </div>
                    </div>
                  );
                })}

                {/* <div className="item">
								<div className="ourteam-div">
									<img src="assets/images/ourteam-img2.jpg" width="0" height="0" alt="" title=""/>
									<h4>Instant Exchange</h4>
									<p>Director</p>
								</div>
							</div>
							<div className="item">
								<div className="ourteam-div">
									<img src="assets/images/ourteam-img3.jpg" width="0" height="0" alt="" title=""/>
									<h4>Experts Support</h4>
									<p>Director</p>
								</div>
							</div>
							<div className="item">
								<div className="ourteam-div">
									<img src="assets/images/ourteam-img4.jpg" width="0" height="0" alt="" title=""/>
									<h4>Doorstep Delivery</h4>
									<p>Director</p>
								</div>
							</div> */}
              </OwlCarousel>
              <Link
                to="/ourteam"
                className="btn btn-primary"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                View All Teams
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="awards-main" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h3 data-aos="fade-up" data-aos-duration="1000">
                Awards
              </h3>
              <OwlCarousel
                id="Awards-slider"
                className="offers owl-theme"
                items={3}
                loop
                nav={true}
                dots={false}
                margin={20}
                autoplay={true}
              >
                {awardlist.map((awardlist) => {
                  return (
                    <div className="item">
                      <div className="awards-div">
                        <img
                          src={imageUrl + awardlist.awardImg}
                          width="0"
                          height="0"
                          alt=""
                          title=""
                        />
                        <h4>{awardlist.title}</h4>
                        <p>{awardlist.description}</p>
                      </div>
                    </div>
                  );
                })}
                {/* <div className="item">
						<div className="awards-div">
							<img src="assets/images/award-img1.png" width="0" height="0" alt="" title=""/>
							<h4>Winner</h4>
							<p>Vivamus porttitor nunc varius elementum finibus.</p>
						</div>
					</div>
					<div className="item">
						<div className="awards-div">
							<img src="assets/images/award-img2.png" width="0" height="0" alt="" title=""/>
							<h4>Instant Exchange</h4>
							<p>Sed tincidunt tellus nec enim condimentum, at sagittis neque consequat.</p>
						</div>
					</div>
					<div className="item">
						<div className="awards-div">
							<img src="assets/images/award-img3.png" width="0" height="0" alt="" title=""/>
							<h4>Experts Support</h4>
							<p>Pellentesque vel enim pulvinar, venenatis sem eget, semper lorem.</p>
						</div>
					</div> */}
              </OwlCarousel>
              <Link
                to="/award"
                className="btn btn-white"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                View All
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Followus />
      <Footer />
    </React.Fragment>
  );
}

export default About;
