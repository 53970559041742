import React, { useEffect, useState } from 'react'
import Marqueepagee from '../marquee/Marqueepagee'
import Breadcrumbpage from '../commonpage/Breadcrumbpage'
import Followus from '../footer/Followus'
import Footer from "../footer/Footer";
import Headertop from '../header/Headertop'
import Headermain from '../header/Headermain'
import { fetchOurServiceHome, fetchOurServiceSlider } from '../../controler/ourservices/ourservices';
// import {fetchOurServiceHome, fetchOurServiceSlider } from '../../controler/home/Home';
import { imageUrl } from '../../controler/ApiHelper';

function Ourservices() {

    // var url="https://forex.weaddo.com";
    const[ourservicehome, setOurServiceHome] = useState([])
    const[ourserviceslider, setOurServiceSlider] = useState([])


    useEffect(() => {
        async function ourservicehome(){
            const data = await fetchOurServiceHome();
            setOurServiceHome(data.data[0]);
        }
        ourservicehome();

        async function ourserviceslider(){
            const data = await fetchOurServiceSlider();
            setOurServiceSlider(data.data);
        }
        ourserviceslider();
    }, [])
  return (
    <React.Fragment>
        <Headertop />
		<div className="innerbanner-main">
				<Headermain />
				<div id="myCarousel" className="carousel slide" data-ride="carousel">
					<div className="carousel-inner">
						<div className="item active">
						<img src="assets/images/ourservices-banner.jpg" width="0" height="0" alt="" title=""/>
						<div className="banner-content hidden-xs">
							<div className="homebannercontent">
								<h1>{ourservicehome.title}</h1>
								<p>{ourservicehome.description}</p>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
        <Marqueepagee />
        <Breadcrumbpage />
        {/* <div className="breadcrumb-main" data-aos="fade-down" data-aos-duration="1500">
            <div className="container">
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                    <li className="breadcrumb-item active">Our Services</li>
                </ol>
                </nav>
            </div>
        </div> */}
        <div className="ourservicespage-main" data-aos="fade-up" data-aos-duration="1500">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h2 data-aos="fade-up" data-aos-duration="1500">{ourservicehome.title}</h2>
                        <p data-aos="fade-up" data-aos-duration="1500">{ourservicehome.shortdesc}</p>
                    </div>
                </div>
                <div className="row">
                    {ourserviceslider.map(ourservicesliderr => {
                        return(
                            <div key={ourserviceslider.indexOf(ourservicesliderr)} className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="500">
                                <div className="ourservice-otherdiv">
                                    <img src={imageUrl+ourservicesliderr.overviewimage} width="0" height="0" alt="" title=""/>
                                    <div className="ourservice-otherdivbox main">
                                        <div className="sub">
                                            <a href="#!">
                                                <h3>{ourservicesliderr.title}</h3>
                                                <p>{ourservicesliderr.overviewDesc} <i className="fal fa-long-arrow-right"></i></p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {/* <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="500">
                        <div className="ourservice-otherdiv">
                            <img src="assets/images/services-img1.jpg" width="0" height="0" alt="" title=""/>
                            <div className="ourservice-otherdivbox main">
                                <div className="sub">
                                    <a href="#!">
                                        <h3>Buy Forex</h3>
                                        <p>Quickest Currency Exchange at Best Rates <i className="fal fa-long-arrow-right"></i></p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1000">
                        <div className="ourservice-otherdiv">
                            <img src="assets/images/services-img2.jpg" width="0" height="0" alt="" title=""/>
                            <div className="ourservice-otherdivbox main">
                                <div className="sub">
                                    <a href="#!">
                                        <h3>Sell Forex</h3>
                                        <p>Quickest Currency Exchange at Best Rates <i className="fal fa-long-arrow-right"></i></p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1500">
                        <div className="ourservice-otherdiv">
                            <img src="assets/images/services-img3.jpg" width="0" height="0" alt="" title=""/>
                            <div className="ourservice-otherdivbox main">
                                <div className="sub">
                                    <a href="#!">
                                        <h3>Remittance</h3>
                                        <p>Quickest Currency Exchange at Best Rates <i className="fal fa-long-arrow-right"></i></p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="2000">
                        <div className="ourservice-otherdiv">
                            <img src="assets/images/services-img4.jpg" width="0" height="0" alt="" title=""/>
                            <div className="ourservice-otherdivbox main">
                                <div className="sub">
                                    <a href="#!">
                                        <h3>Travel Card</h3>
                                        <p>Quickest Currency Exchange at Best Rates <i className="fal fa-long-arrow-right"></i></p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="2500">
                        <div className="ourservice-otherdiv">
                            <img src="assets/images/services-img5.jpg" width="0" height="0" alt="" title=""/>
                            <div className="ourservice-otherdivbox main">
                                <div className="sub">
                                    <a href="#!">
                                        <h3>Travel Insurance</h3>
                                        <p>Quickest Currency Exchange at Best Rates <i className="fal fa-long-arrow-right"></i></p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
        <Followus />
        <Footer />
    </React.Fragment>
  )
}

export default Ourservices
