import React, { useEffect, useState } from 'react'
import Followus from '../footer/Followus'
import Footer from "../footer/Footer";
import Breadcrumbpage from '../commonpage/Breadcrumbpage'
import Headermain from '../header/Headermain';
import Headertop from '../header/Headertop';
import Marqueepagee from '../marquee/Marqueepagee';
import { fetchBlogBanner, fetchBlogGrid } from '../../controler/blog/blog';
import { imageUrl } from '../../controler/ApiHelper';

function Blogdetails() {
    // var url ="https://forex.weaddo.com"
    const[blogbanner, setBlogBanner] = useState([])
    const[bloggrid, setBlogGrid] = useState([])

    useEffect(() =>{
        async function blogbanner() {
            const data = await fetchBlogBanner();
            setBlogBanner(data.data[0]);
            console.log("Blog banner data show here", data.data);
        }
        blogbanner();

        async function bloggrid() {
            const data = await fetchBlogGrid();
            setBlogGrid(data.data);
            console.log("Blog grid data show here", data.data);
        }
        bloggrid();

    }, [])

  return (
    <React.Fragment>
        <Headertop />
        <div className="innerbanner-main">
				<Headermain />
				<div id="myCarousel" className="carousel slide" data-ride="carousel">
					<div className="carousel-inner">
						<div className="item active">
							<img src={imageUrl+blogbanner.bannerImg} width="0" height="0" alt="" title=""/>
							<div className="banner-content hidden-xs">
								<div className="homebannercontent">
									<h1>{blogbanner.title}</h1>
									<p>{blogbanner.description}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        <Marqueepagee />
        <Breadcrumbpage />
        <div className="latestblogdetail-main" data-aos="fade-up" data-aos-duration="500">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-sm-7" data-aos="fade-up" data-aos-duration="1000">
                        <div className="latectblogdetailleftdiv">
                            <h1>{bloggrid.title}</h1>
                            <h6>{bloggrid.author} <span>{bloggrid.date}</span></h6>
                            <p>{bloggrid.longdesc} 
                            </p>
                            <p>						
                                Accept to obtain some advantage from it? But who has any right find fault with a man who chooses to enjoy a 
                                pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasurethere 
                                anyone loves or pursues.
                            </p>
                            <div className="latestblogdetailquetoo">
                                <h5>There anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because 
                                    occasionally circumstances occur in which toil and pain can procure him some great pleasure. 
                                </h5>
                                <h6>Quick Forex <br />
                                    Founder
                                </h6>
                            </div>
                            <p>Explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you 
                                a complete account of the system, and expound the actual teachings of the explorer of the truth.
                            </p>
                            <p>The master-builder of human happiness which toil and pain can procure him some seds great pleasure take a of
                                ever.
                            </p>
                            {/* <h3>Comments</h3>
                            <ul>
                                <li>
                                    <h4>Steven Rich 
                                        <span>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star-half"></i>
                                        </span>
                                    </h4>
                                    <p>How all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a 
                                        complete account of the system, and expound the actual teachings.
                                    </p>
                                    <h5>Sep 17, 2016</h5>
                                </li>
                                <li>
                                    <h4>Steven Rich 
                                        <span>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star-half"></i>
                                        </span>
                                    </h4>
                                    <p>How all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a 
                                        complete account of the system, and expound the actual teachings.
                                    </p>
                                    <h5>Sep 17, 2016</h5>
                                </li>
                            </ul>
                            <h3>Leave Your Comments</h3>
                            <div className="form-div">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="First Name*" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Last Name*" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Email*" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Mobile No*" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <textarea className="form-control" placeholder="Write Your Comments"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <a href="#!" className="btn btn-blue">Submit</a>
                                    </div>
                                </div>
                                
                            </div> */}
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-5" data-aos="fade-up" data-aos-duration="1500">
                        <div className="latectblogdetailrightdiv">
                            {/* <form className="example" action="/action_page.php">
                                <input type="text" placeholder="Search.." name="search2" />
                                <button type="submit"><i className="fa fa-search"></i></button>
                            </form> */}
                            <h3>Popular Post</h3>
                            <ul>
                                {bloggrid.map(bloggridd => {
                                    return(
                                        <li>
                                        <a href="#!">
                                            <img src={imageUrl+bloggridd.blogsThumbnailImage} width="0" height="0" alt="" title=""/>
                                            <p>{bloggridd.description}</p>
                                            <h6>{bloggridd.date}</h6>
                                        </a>
                                    </li>
                                    )
                                })}
                                {/* <li>
                                    <a href="#!">
                                        <img src="assets/images/popular-img.jpg" width="0" height="0" alt="" title=""/>
                                        <p>Nullam suscipit nulla telementum lobortis luctus.</p>
                                        <h6>July 25, 2017</h6>
                                    </a>
                                </li>
                                <li>
                                    <a href="#!">
                                        <img src="assets/images/popular-img.jpg" width="0" height="0" alt="" title=""/>
                                        <p>Nullam suscipit nulla telementum lobortis luctus.</p>
                                        <h6>July 25, 2017</h6>
                                    </a>
                                </li>
                                <li>
                                    <a href="#!">
                                        <img src="assets/images/popular-img.jpg" width="0" height="0" alt="" title=""/>
                                        <p>Nullam suscipit nulla telementum lobortis luctus.</p>
                                        <h6>July 25, 2017</h6>
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Followus />
        <Footer />
    </React.Fragment>
  )
}

export default Blogdetails
