import React from 'react'
import Marqueepagee from '../marquee/Marqueepagee'
import Breadcrumbpage from '../commonpage/Breadcrumbpage'
import Followus from '../footer/Followus'
import Footer from "../footer/Footer";
import Headertop from '../header/Headertop'
import Headermain from '../header/Headermain'
import { useState } from 'react';
import { useEffect } from 'react';
import { fetchOurServiceSlider } from '../../controler/ourservices/ourservices';
import { fetchGetStartedHomeStep } from '../../controler/getstarted/getstarted';
import { fetchCityFormList, fetchRemittanceContant, fetchRemittanceForm } from '../../controler/remittance/remittance';
import { useFormik } from 'formik';
import { remittanceFormSchema } from '../../schemas';
import {fetchSelectCurrency } from '../../controler/home/Home';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { imageUrl } from '../../controler/ApiHelper';

const initialValues = {
    fullname: '',
    phone : '',
    email:'',
    location: '',
    currency: '',
    forexAmount :'',
    inrAmount: ''
}
function Remittance() {
    const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues,
        validationSchema : remittanceFormSchema,
        onSubmit: async(values, action) => {
            console.log("contcat form validation ", values);
            const data = await fetchRemittanceForm(values);
            console.log("Remmitance form validation ", data);
            action.resetForm();
            toast.success("Your data has been recorded")
          }
         
    })
    // if(errors){
    //     console.log("errors");
    // }
    console.log("erreorrrrrr",errors)
    // var url = "https://forex.weaddo.com";
	const[ourserviceslider, setOurServiceSlider] = useState([])
	const[ourservicefaq, setOurServicefaq] = useState([])
	const[getstartedhomestep, setGetStartedHomeStepData] =useState([])
	const[active, setActive] = useState(0)
    const[remittancecontent, setRemittanceContant] = useState([])
    const [selectcurrency, setSelectCurrency] = useState([])
    const[cityformlist, setCityFormList] = useState ([])
	
	useEffect(() => {
        async function selectcurrency () {
            const data = await fetchSelectCurrency ();
            setSelectCurrency(data.data);
            // console.log("currency data", data.data)
        }
        selectcurrency();

        async function cityformlist () {
            const data = await fetchCityFormList ();
            setCityFormList(data.data);
            // console.log("currency data", data.data)
        }
        cityformlist();

		async function ourserviceslider () {
			let remittancefaq = []
			const data = await fetchOurServiceSlider();
			for(let i=0;i<data.data.length;i++){
				if(data.data[i].title === "Remittance"){
					remittancefaq.push(data.data[i].faq_category)
				}
			}

			for(let i=0;i<data.data.length;i++){
				if(data.data[i].title==="Remittance"){
					setOurServiceSlider(data.data[i]);
				}
			}
			setOurServicefaq(...remittancefaq);
			// console.log("faq chnagesssssssssssssssssssss", data.data[0].faq_category,remittancefaq)
		}
		ourserviceslider();

		async function getstartedhomestep (){
			let sellforexgetstartedcode = []
			const data = await fetchGetStartedHomeStep();
			for(let i=0; i<data.data.length;i++){
				if(data.data[i].pageName === "Remittance"){
					sellforexgetstartedcode.push(data.data[i])
				}
			}
			setGetStartedHomeStepData(sellforexgetstartedcode);
		// console.log("get Started data", data.data);
		}
		getstartedhomestep();

        async function remittancecontent () {
            const data = await fetchRemittanceContant();
            setRemittanceContant(data.data[0]);
            // console.log("Remittance Conatnt show", data.data);
        }
        remittancecontent();

	},[])
	const handleActive=(val)=>{
		// console.log("valllllll",val);
		setActive(val)
			}
  return (
    <React.Fragment>
        <Headertop />
		<div className="innerbanner-main">
				<Headermain />
				<div id="myCarousel" className="carousel slide" data-ride="carousel">
					<div className="carousel-inner">
						<div className="item active">
							<img src={imageUrl+ourserviceslider.detailBannerImage} width="0" height="0" alt="" title=""/>
							<div className="banner-content hidden-xs">
								<div className="homebannercontent">
									<h1>{ourserviceslider.title}</h1>
									<p>{ourserviceslider.overviewDesc}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        <Marqueepagee />
        <Breadcrumbpage />
        <div className="buforex-main" data-aos="fade-down" data-aos-duration="1500">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <ul className="nav nav-tabs">
                            <li><a data-toggle="tab" href="#currencyexchange">
                                <img src="assets/images/form-icon1.png" width="0" height="0" alt="" title=""/> Currency Exchange
                            </a></li>
                            <li className="active"><a data-toggle="tab" href="#remittance">
                                <img src="assets/images/form-icon2.png" width="0" height="0" alt="" title=""/> Remittance</a></li>
                            <li><a data-toggle="tab" href="#sendmoneyaboad">
                                <img src="assets/images/form-icon3.png" width="0" height="0" alt="" title=""/> Send Money Abroad</a></li>
                            <li><a data-toggle="tab" href="#travelcard">
                                <img src="assets/images/form-icon3.png" width="0" height="0" alt="" title=""/> Travel Card</a></li>
                            <li><a data-toggle="tab" href="#travelinsurance">
                                <img src="assets/images/form-icon3.png" width="0" height="0" alt="" title=""/> Travel Insurance</a></li>
                        </ul>
                        </div>
                        <div className="col-sm-12" data-aos="fade-up" data-aos-duration="1500">
                        <div className="tab-content">
                            <div id="remittance" className="tab-pane fade in active">
                                <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <input 
                                            type="text"
                                            name='fullname'
                                            value={values.fullname}
                                            autoComplete='off'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                             className="form-control" 
                                             placeholder="Full Name" 
                                             />
                                             {errors.fullname && touched.fullname ? <p className='form-errors'>{errors.fullname}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <input 
                                            type="text" 
                                            name='phone'
                                            value={values.phone}
                                            autoComplete='off'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            className="form-control" 
                                            placeholder="Mobile No." 
                                            />
                                            {errors.phone && touched.phone ? <p className='form-errors'>{errors.phone}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <input 
                                            type="text" 
                                            name='email'
                                            className="form-control" 
                                            placeholder="E-mail Address" 
                                            autoComplete='off'
                                            value={values.email}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            />
                                            {errors.email && touched.email ? <p className='form-errors'>{errors.email}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <select 
                                            className="form-control"
                                            name='location'
                                            autoComplete='off'
                                            value={values.location}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            >
                                                <option>Location</option>
                                                {cityformlist.map(cityformlistyy => (
                                                    <option>{cityformlistyy.city}</option>
                                                ))}
                                            </select>
                                            {errors.location && touched.location ? <p className='form-errors'>{errors.location}</p> : null}
                                        </div>
                                    </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>Currency to be remittance</label>
                                                <select 
                                                autoComplete='off'
                                                name='currency'
                                                value={values.currency}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                className="form-control">
                                                    <option>Select Currency</option>
                                                    {selectcurrency.map(selectcurrencyyy => (
                                                        <option>{selectcurrencyyy.currency}</option>
                                                    ))}
                                                    
                                                </select>
                                                <span>Rate: 1 USD = Rs.84.29</span>
                                                {errors.currency && touched.currency ? <p className='form-errors'>{errors.currency}</p> : null}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>Forex Amount</label>
                                                <input type="text" 
                                                name='forexAmount' 
                                                className="form-control" 
                                                placeholder="Forex Amount" 
                                                autoComplete='off'
                                                value={values.forexAmount}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                />
                                                <img src="assets/images/inr-icon.png" width="0" height="0" alt="" title=""/>
                                                {errors.forexAmount && touched.forexAmount ? <p className="form-errors">{errors.forexAmount}</p> : null}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>INR Amount</label>
                                                <input type="text" 
                                                name='inrAmount'
                                                className="form-control" 
                                                placeholder="Amount in INR"
                                                autoComplete='off'
                                                value={values.inrAmount} 
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                />
                                                <img src="assets/images/inr-icon.png" width="0" height="0" alt="" title=""/>
                                                {errors.inrAmount && touched.inrAmount ? <p className="form-errors">{errors.inrAmount}</p> : null}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>Receiving Amount</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 text-right">
                                            <button type='submit' className="btn btn-blue">Send Money</button>
                                        </div>
                                </div>
                                </form>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-12 currentexchangediv">
                                        <p>Please Fill your Details</p>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <input type="text" className="form-control" placeholder="Enter City" />
                                            </div>
                                            <div className="col-sm-3">
                                                <input type="text" className="form-control" placeholder="Mobile No." />
                                            </div>
                                            <div className="col-sm-3">
                                                <input type="text" className="form-control" placeholder="E-Mail Address" />
                                            </div>
                                            <div className="col-sm-3">
                                                <a href="#!" className="btn btn-primary">Get a Quote</a>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="radiobutton">
                                                    <div className="radiobuttonleftother">
                                                        <div className="form__radio-group">
                                                            <input type="radio" name="other" id="other" className="form__radio-input" value="3" />
                                                            <label className="form__label-radio form__radio-label" for="other">
                                                            <span className="form__radio-button"></span> I accept the <a href="#!">Privacy Policy</a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="getstartednew-main aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h3>Get Started</h3>
                        <ul>
                        {getstartedhomestep.map(getstartedhomesteppp => {
								return(
									<li>
										<img src={imageUrl+getstartedhomesteppp.icon} width="0" height="0" alt="" title=""/>
										<h4>{getstartedhomesteppp.text}</h4>
										<p>{getstartedhomesteppp.desc}</p>
									</li>
								)
							})}
                            {/* <li>
                                <img src="assets/images/getstarted-icon1.png" width="0" height="0" alt="" title=""/>
                                <h4>STEP-1</h4>
                                <p>Select currency and denomination you need to carry along.</p>
                            </li>
                            <li>
                                <img src="assets/images/getstarted-icon2.png" width="0" height="0" alt="" title=""/>
                                <h4>STEP-2</h4>
                                <p>Enter traveller details & delivery option doorstep delivery or brancg pick up.</p>
                            </li>
                            <li>
                                <img src="assets/images/getstarted-icon3.png" width="0" height="0" alt="" title=""/>
                                <h4>STEP-3</h4>
                                <p>Receipt of forex  order completion at  blocked rate</p>
                            </li>
                            <li>
                                <img src="assets/images/getstarted-icon3.png" width="0" height="0" alt="" title=""/>
                                <h4>STEP-4</h4>
                                <p>Receipt of forex  order completion at  blocked rate</p>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="travelinsurance-main" style={{backgroundColor:'#fff'}} data-aos="fade-up" data-aos-duration="1500">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h1 data-aos="fade-up" data-aos-duration="500">{ourserviceslider.title}</h1>
                        <p data-aos="fade-up" data-aos-duration="1500"><b>"{ourserviceslider.detailSubHeading}"</b></p>
                        <div className="travelinsurance-contant">
                            <div className="travelinsurance-contantleft" data-aos="fade-up" data-aos-duration="1500">
                                <p>{ourserviceslider.detailDesc}
                                </p>
                                <p>If you want to send money abroad or remit money to India, then you have arrived at the right destination.
                                </p>
                            </div>
                            <div className="travelinsurance-contantright" data-aos="fade-down" data-aos-duration="1500">
                                <img src={imageUrl+ourserviceslider.detailImage} width="0" height="0" alt="" title=""/>
                            </div>
                        </div>
                        <div className="smoothtransfar-div" data-aos="fade-up" data-aos-duration="1500">
                            <ul className="nav nav-tabs">
                                <li className="active"><a data-toggle="tab" href="#smoothtransfar">
                                    {remittancecontent.title1}
                                </a></li>
                            </ul>
                            <div className="tab-content">
                                <div id="smoothtransfar" className="tab-pane fade in active">
                                    <p>{remittancecontent.desc1}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="Realtime-main" data-aos="fade-up" data-aos-duration="1500">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h3 data-aos="fade-down" data-aos-duration="1500">{remittancecontent.title2}</h3>
                        <div className="Realtime-div">
                            <div className="Realtime-leftdiv" data-aos="fade-up" data-aos-duration="1500">
                                <img src={imageUrl+remittancecontent.bannerImg} width="0" height="0" alt="" title=""/>
                            </div>
                            <div className="Realtime-rightdiv" data-aos="fade-down" data-aos-duration="1500">
                                <p>{remittancecontent.desc2}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="faq-main aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
            <div className="container">
                <div className="row">
                    <div className="col-lg-offset-2 col-lg-8">
                        <h3>FAQ's</h3>
                        <div className="panel-group" id="accordion1">
                        {ourservicefaq.map(faq => {
						return(
						<div className="panel">
							<div className="panel-heading" onClick={() => handleActive(ourservicefaq.indexOf(faq))} >
							<h4 className="panel-title">
									<a className={ourservicefaq.indexOf(faq) === active ? "accordion-toggle1" : "accordion-toggle1 collapsed"} data-toggle="collapse" 
									data-parent="#accordion1 " href="#panelBody1">{faq.title}</a>
							</h4>
							</div>
							<div id={ourservicefaq.indexOf(faq)} className={ourservicefaq.indexOf(faq) === active ? "panel-collapse collapse in": "panel-collapse collapse"}>
								<div className="panel-body">
								<p>{faq.description}
								</p>
								</div>
						</div>
				  </div>
					)})}

                            {/* <div className="panel">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle1" data-toggle="collapse" data-parent="#accordion1 " href="#panelBody1">Nam imperdiet sem in fringilla placerat.</a>
                                    </h4>
                                </div>
                                <div id="panelBody1" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                            the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                            handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle1 collapsed" data-toggle="collapse" data-parent="#accordion1" href="#panelBody2">
                                            Integer sit amet odio id enim hendrerit volutpat vel sed dolor.
                                        </a>
                                    </h4>
                                </div>
                                <div id="panelBody2" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                                    the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                                    handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle1 collapsed" data-toggle="collapse" data-parent="#accordion1" href="#panelBody3">
                                            Integer sit amet odio id enim hendrerit volutpat vel sed dolor.
                                        </a>
                                    </h4>
                                </div>
                                <div id="panelBody3" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                                    the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                                    handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle1 collapsed" data-toggle="collapse" data-parent="#accordion1" href="#panelBody4">
                                            Integer sit amet odio id enim hendrerit volutpat vel sed dolor.
                                        </a>
                                    </h4>
                                </div>
                                <div id="panelBody4" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                                    the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                                    handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Followus />
        <Footer />
        <ToastContainer />
    </React.Fragment>
  )
}

export default Remittance
