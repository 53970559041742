import React from 'react';
import Routerpage from './component/Home/RouterPage';
// import 'bootstrap/dist/css/bootstrap.min.css';



function App() {
  return (
    <div className="App">
      <>
      <Routerpage />
      </>
      
    </div>
  );
}

export default App;
