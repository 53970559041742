import Endpoints from "../Endpoints";
import { baseUrl } from "../ApiHelper";

export const fetchFaqList = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.faqlist}`);
    return await data.json();
};

export const fetchFaqBanner = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.faqbanner}`);
    return await data.json();
};