import React from 'react'
import Marquee from "react-fast-marquee";
function Marqueepagee() {
const marqdiv=[
	{
		buytagname:"USD",
		buytag:"Buy",
		buytagprice:":81.99",
		buyspan:"/",
		selltab:"Sell",
		sellsmalltag:":84.03975",
		sellspan:"|",
	},
	{
		buytagname:"Euro",
		buytag:"Buy",
		buytagprice:":91.26",
		buyspan:"/",
		selltab:"Sell",
		sellsmalltag:":93.5415",
		sellspan:"|",
	},
	{
		buytagname:"British Pound",
		buytag:"Buy",
		buytagprice:":8105.54",
		buyspan:"/",
		selltab:"Sell",
		sellsmalltag:":108.1785",
		sellspan:"|",
	},
	{
		buytagname:"Australian Dollar",
		buytag:"Buy",
		buytagprice:":55.38",
		buyspan:"/",
		selltab:"Sell",
		sellsmalltag:":56.7645",
		sellspan:"|",
	},
	{
		buytagname:"Canadian Dollar",
		buytag:"Buy",
		buytagprice:":62.23",
		buyspan:"/",
		selltab:"Sell",
		sellsmalltag:":63.78575",
		sellspan:"|",
	},
	{
		buytagname:"Singapore Dollar",
		buytag:"Buy",
		buytagprice:":61.732",
		buyspan:"/",
		selltab:"Sell",
		sellsmalltag:":63.2753",
		sellspan:"|",
	},
	{
		buytagname:"New Zealand Dollar",
		buytag:"Buy",
		buytagprice:":50.88",
		buyspan:"/",
		selltab:"Sell",
		sellsmalltag:":52.152",
		sellspan:"|",
	},
	{
		buytagname:"Hong Kong Dollar",
		buytag:"Buy",
		buytagprice:":10.49",
		buyspan:"/",
		selltab:"Sell",
		sellsmalltag:":10.75225",
		sellspan:"|",
	},
	{
		buytagname:"UAE Dirham AED",
		buytag:"Buy",
		buytagprice:":22.33",
		buyspan:"/",
		selltab:"Sell",
		sellsmalltag:":22.88825",
		sellspan:"|",
	},
	{
		buytagname:"Saudi Riyal",
		buytag:"Buy",
		buytagprice:":21.88",
		buyspan:"/",
		selltab:"Sell",
		sellsmalltag:":22.427",
		sellspan:"|",
	},
	{
		buytagname:"CHF Swiss Franc ",
		buytag:"Buy",
		buytagprice:":94.66",
		buyspan:"/",
		selltab:"Sell",
		sellsmalltag:":97.0265",
		sellspan:"|",
	},
	{
		buytagname:"Japanese Yen",
		buytag:"Buy",
		buytagprice:":0.5797",
		buyspan:"/",
		selltab:"Sell",
		sellsmalltag:":0.5941925",
		sellspan:"|",
	},
	{
		buytagname:"Swedish Krona",
		buytag:"Buy",
		buytagprice:":7.89",
		buyspan:"/",
		selltab:"Sell",
		sellsmalltag:":8.08725",
		sellspan:"|",
	},
	{
		buytagname:"Thai Baht CARD",
		buytag:"Buy",
		buytagprice:":2.38",
		buyspan:"/",
		selltab:"Sell",
		sellsmalltag:":2.4395",
		sellspan:"|",
	},
	{
		buytagname:"South African Rand",
		buytag:"Buy",
		buytagprice:":4.56",
		buyspan:"/",
		selltab:"Sell",
		sellsmalltag:":4.674",
		sellspan:"|",
	},
	{
		buytagname:"Norwegian Krone",
		buytag:"Buy",
		buytagprice:":7.7676",
		buyspan:"/",
		selltab:"Sell",
		sellsmalltag:":7.96179",
		sellspan:"|",
	},
	{
		buytagname:"Danish Krone",
		buytag:"Buy",
		buytagprice:":12.24",
		buyspan:"/",
		selltab:"Sell",
		sellsmalltag:":12.546",
	},

]
marqdiv.sort(function (a, b) {
	if (a.buytagname < b.buytagname) {
	  return -1;
	}
	if (a.buytagname > b.buytagname) {
	  return 1;
	}
	return 0;
  });
  
  console.log(marqdiv);
  return (
    <React.Fragment>
    <div className="marquee-main">
		<Marquee 
		speed="50"
		pauseOnHover="true">
			<p> 
				
					{marqdiv.map((marquee) => (
						marqdiv.indexOf(marquee) === marqdiv.length-1?
							<>
								<span>{marquee.buytagname} <span>{marquee.buytag}</span><small> {marquee.buytagprice}</small> {marquee.buyspan} <span>{marquee.selltab}</span><small>{marquee.sellsmalltag}</small></span>
								</>
								:
									<>
										<span>{marquee.buytagname} <span>{marquee.buytag}</span><small> {marquee.buytagprice}</small> {marquee.buyspan} <span>{marquee.selltab}</span><small>{marquee.sellsmalltag}</small></span> <span className="betweentag">{marquee.sellspan}</span>
										</>
									
					))}
				</p>
				 {/* <span>USD <span>Buy</span><small>:81.1</small> / <span>Sell</span><small>: 81.1445</small></span> <span className="betweentag">|</span>
				 <span>USD <span>Buy</span><small>:81.1</small> / <span>Sell</span><small>: 81.1445</small></span> <span className="betweentag">|</span>
				<span>USD <span>Buy</span><small>:81.1</small> / <span>Sell</span><small>: 81.1445</small></span> <span className="betweentag">|</span>
				 <span>USD <span>Buy</span><small>:81.1</small> / <span>Sell</span><small>: 81.1445</small></span> <span className="betweentag">|</span>
				<span>USD <span>Buy</span><small>:81.1</small> / <span>Sell</span><small>: 81.1445</small></span> <span className="betweentag">|</span>
				<span>USD <span>Buy</span><small>:81.1</small> / <span>Sell</span><small>: 81.1445</small></span> */}
            
		</Marquee>
	</div>
    </React.Fragment>
  )
}

export default Marqueepagee