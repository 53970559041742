import React, { useEffect, useState } from 'react'
import Headertop from '../header/Headertop'
import Headermain from '../header/Headermain'
import Marqueepagee from '../marquee/Marqueepagee'
import Breadcrumbpage from '../commonpage/Breadcrumbpage'
import { imageUrl } from '../../controler/ApiHelper'
import { fetchOurServiceSlider } from '../../controler/ourservices/ourservices'
import Footer from '../footer/Footer'
import Followus from '../footer/Followus'
import { fetchGetStartedHomeStep } from '../../controler/getstarted/getstarted'
import { useFormik } from 'formik'
import { sendMoneyAbroadFormSchema } from '../../schemas'
import {fetchSelectCurrency, fetchSendMoneyAbroadForm } from '../../controler/home/Home'

const initialValues ={
    currency : "",
    forexAmount : "",
    inrAmount : ""
}

function Sendmoneyabroad() {
    const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues,
        validationSchema : sendMoneyAbroadFormSchema,
        onSubmit : async (values, action) =>{
            const data = await fetchSendMoneyAbroadForm(values);
            // console.log("Send Money Abroad For" , data);
            action.resetForm();
        }
    })
    // console.log("formik Send Money Abroad ssssss Form Handling ", errors);

    const[ourserviceslider, setOurServiceSlider] = useState([])
    const[ourservicefaq, setOurServicefaq] = useState([])
    const[getstartedhomestep, setGetStartedHomeStepData] = useState([])
    const[active, setActive] = useState(0)
    const [selectcurrency, setSelectCurrency] = useState([])
    useEffect(() => {
        async function selectcurrency(){
            const data = await fetchSelectCurrency();
            setSelectCurrency(data.data);
            // console.log("currency data show hereeeeee", data.data);
        }
        selectcurrency();

        async function getstartedhomestep (){
			let sendmoneyabroadgetstartedcode = []
			const data = await fetchGetStartedHomeStep();
			for(let i=0; i<data.data.length;i++){
				if(data.data[i].pageName === "Send Money Abroad"){
					sendmoneyabroadgetstartedcode.push(data.data[i])
				}
			}
			setGetStartedHomeStepData(sendmoneyabroadgetstartedcode);
		// console.log("get Started data", travelcardgetstartedcode);
		}
		getstartedhomestep();

        async function ourserviceslider () {
			let travelcardfaq = []
			const data = await fetchOurServiceSlider();
			for(let i=0;i<data.data.length;i++){
				if(data.data[i].title === "Send Money Abroad"){
					travelcardfaq.push(data.data[i].faq_category)
				}
			}

			for(let i=0;i<data.data.length;i++){
				if(data.data[i].title ==="Send Money Abroad"){
					setOurServiceSlider(data.data[i]);
				}
			}
			setOurServicefaq(...travelcardfaq);
			// console.log("Travel card faq chnagesssssssssssssssssssss", data.data[0].faq_category,travelcardfaq)
		}
		ourserviceslider();
    },[])
    const handleActive=(val)=>{
		// console.log("valllllll",val);
		setActive(val)
			}
  return (
    <React.Fragment>
    <Headertop />
    <div className="innerbanner-main">
            <Headermain />
            <div id="myCarousel" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="item active">
                        <img src={imageUrl+ourserviceslider.detailBannerImage} width="0" height="0" alt="" title=""/>
                        <div className="banner-content hidden-xs">
                            <div className="homebannercontent">
                                <h1>{ourserviceslider.title}</h1>
                                <p>{ourserviceslider.overviewDesc}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <Marqueepagee />
    <Breadcrumbpage />
    <div className="buforex-main" data-aos="fade-up" data-aos-duration="1500">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <ul className="nav nav-tabs">
                            <li><a data-toggle="tab" href="#currencyexchange"><img src="assets/images/form-icon1.png" alt='' title=''/> Currency Exchange
                            </a></li>
                            <li><a data-toggle="tab" href="#remittance"><img src="assets/images/form-icon2.png" alt='' title=''/> Remittance</a></li>
                            <li  className="active"><a data-toggle="tab" href="#sendmoneyaboad"><img src="assets/images/form-icon3.png" alt='' title=''/> Send Money Abroad</a></li>
                            <li><a data-toggle="tab" href="#forexcard"><img src="assets/images/form-icon3.png" alt='' title=''/> Travel Card</a></li>
                            <li><a data-toggle="tab" href="#travelinsurance"><img src="assets/images/form-icon3.png" alt='' title=''/> Travel Insurance</a></li>
                        </ul>
                        </div>
                        <div className="col-sm-12">
                        <div className="tab-content">
                            <div id="sendmoneyaboad" className="tab-pane fade in active">
                                <div className="row">
                                    <form onSubmit={handleSubmit}>
                                    <div id="Cars2" className="desc">
                                        <div className="col-md-3 col-sm-6">
                                            <div className="form-group">
                                                <label >Check Rate</label>
                                                <select className="form-control">
                                                    <option>One Currency Card</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="form-group">
                                                <label>Currency</label>
                                                <select 
                                                name='currency'
                                                autoComplete='off'
                                                value={values.currency}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="form-control"
                                                >
                                                    <option>Select Currency</option>
                                                    {selectcurrency.map(selectcurrencyyy => (
                                                        <option>{selectcurrencyyy.currency}</option>
                                                    ))}
                                                    
                                                </select>
                                                <span>Rate: 1 USD = Rs.84.29</span>
                                                {errors.currency && touched.currency ? <p className='form-errors'>{errors.currency}</p> : null}
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="form-group">
                                                <label>Forex Amount</label>
                                                <input 
                                                type="text" 
                                                autoComplete='off'
                                                name='forexAmount'
                                                value={values.forexAmount}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="form-control" 
                                                placeholder="Forex Amount" 
                                                />
                                                <img src="assets/images/inr-icon.png" alt='' title=''/>
                                                {errors.forexAmount && touched.forexAmount ? <p className='form-errors'>{errors.forexAmount}</p> : null}
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="form-group">
                                                <label>INR Amount</label>
                                                <input 
                                                type="text" 
                                                name='inrAmount'
                                                autoComplete='off'
                                                value={values.inrAmount}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="form-control" 
                                                placeholder="Amount in INR" 
                                                />
                                                <img src="assets/images/inr-icon.png" alt='' title=''/>
                                                {errors.inrAmount && touched.inrAmount ? <p className='form-errors'>{errors.inrAmount}</p> : null}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 text-right">
                                            <button type='submit' className="btn btn-blue">Send Money Abroad</button>
                                        </div>
                                    </div>
                                    </form>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-lg-offset-2 col-lg-8 col-sm-12 currentexchangediv">
                                        <p>Communication Details (You order confirmation details will be sent on these contact details)</p>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <input type="text" className="form-control" placeholder="Mobile No." />
                                            </div>
                                            <div className="col-sm-4">
                                                <input type="text" className="form-control" placeholder="E-Mail Address" />
                                            </div>
                                            <div className="col-sm-4">
                                                <a href="#!" className="btn btn-primary">Get a Quote</a>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="radiobutton">
                                                    <div className="radiobuttonleftother">
                                                        <div className="form__radio-group">
                                                            <input type="radio" name="other" id="other" className="form__radio-input" value="3" />
                                                            <label className="form__label-radio form__radio-label" for="other">
                                                            <span className="form__radio-button"></span> I accept the <a href="#!">Privacy Policy</a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <div className="travelinsurance-main">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h1 data-aos="fade-up" data-aos-duration="1500">{ourserviceslider.title}</h1>
                        <p data-aos="fade-up" data-aos-duration="1500"><b>"{ourserviceslider.detailSubHeading}"</b></p>
                        <div className="travelinsurance-contant">
                            <div className="travelinsurance-contantleft" data-aos="fade-up" data-aos-duration="1500">
                                <p>{ourserviceslider.detailDesc}
                                </p>
                            </div>
                            <div className="travelinsurance-contantright" data-aos="fade-down" data-aos-duration="1500">
                                <img src={imageUrl+ourserviceslider.detailImage} alt='' title=''/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="getstartednew-main aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h3>Get Started</h3>
                        <ul>
                        {getstartedhomestep.map(getstartedhomesteppp => {
								return(
									<li key={getstartedhomestep.indexOf(getstartedhomesteppp)}>
										<img src={imageUrl+getstartedhomesteppp.icon} width="0" height="0" alt="" title=""/>
										<h4>{getstartedhomesteppp.text}</h4>
										<p>{getstartedhomesteppp.desc}</p>
									</li>
								)
							})}
                            {/* <li>
                                <img src="assets/images/getstarted-icon1.png" width="0" height="0" alt="" title=""/>
                                <h4>STEP-1</h4>
                                <p>Select currency and denomination you need to carry along.</p>
                            </li>
                            <li>
                                <img src="assets/images/getstarted-icon2.png" width="0" height="0" alt="" title=""/>
                                <h4>STEP-2</h4>
                                <p>Enter traveller details & delivery option doorstep delivery or brancg pick up.</p>
                            </li>
                            <li>
                                <img src="assets/images/getstarted-icon3.png" width="0" height="0" alt="" title=""/>
                                <h4>STEP-3</h4>
                                <p>Receipt of forex  order completion at  blocked rate</p>
                            </li>
                            <li>
                                <img src="assets/images/getstarted-icon3.png" width="0" height="0" alt="" title=""/>
                                <h4>STEP-4</h4>
                                <p>Receipt of forex  order completion at  blocked rate</p>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="faq-main aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
            <div className="container">
                <div className="row">
                    <div className="col-lg-offset-2 col-lg-8">
                        <h3 data-aos="fade-up" data-aos-duration="1500">FAQ's</h3>
                        <div className="panel-group" id="accordion1" data-aos="fade-up" data-aos-duration="1500">
                            {/* {ourservicefaq.map(faq => {
                            return(
                            <div className="panel">
                                <div className="panel-heading" onClick={() => handleActive(ourservicefaq.indexOf(faq))} >
                                <h4 className="panel-title">
                                        <a className={ourservicefaq.indexOf(faq) === active ? "accordion-toggle1" : "accordion-toggle1 collapsed"}
                                        data-toggle="collapse" 
                                        data-parent="#accordion1 " href="#panelBody1">{faq.title}</a>
                                </h4>
                                </div>
                                <div id={ourservicefaq.indexOf(faq)} className={ourservicefaq.indexOf(faq) === active ? "panel-collapse collapse in": "panel-collapse collapse"}>
                                    <div className="panel-body">
                                    <p>{faq.description}
                                    </p>
                                    </div>
                            </div>
                        </div>
					)})} */}
                    {ourservicefaq.map(ourservicefaqqq => {
                        return (
                            <div className="panel">
                                <div className="panel-heading" onClick={() => handleActive(ourservicefaq.indexOf(ourservicefaqqq))}>
                                    <h4 className="panel-title">
                                        <a className={ourservicefaq.indexOf(ourservicefaqqq) === active ? "accordion-toggle1" : "accordion-toggle1 collapsed"}
                                         data-toggle="collapse" data-parent="#accordion1 " href="#panelBody1">
                                            {ourservicefaqqq.title}</a>
                                    </h4>
                                </div>
                                <div id={ourservicefaq.indexOf(ourservicefaqqq)} className={ourservicefaq.indexOf(ourservicefaqqq) === active ? "panel-collapse collapse in" :
                            "panel-collapse collapse" }>
                                    <div className="panel-body">
                                        <p>{ourservicefaqqq.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                            {/* <div className="panel">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle1" data-toggle="collapse" data-parent="#accordion1 " href="#panelBody1">Nam imperdiet sem in fringilla placerat.</a>
                                    </h4>
                                </div>
                                <div id="panelBody1" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                            the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                            handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle1 collapsed" data-toggle="collapse" data-parent="#accordion1" href="#panelBody2">
                                            Integer sit amet odio id enim hendrerit volutpat vel sed dolor.
                                        </a>
                                    </h4>
                                </div>
                                <div id="panelBody2" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                                    the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                                    handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle1 collapsed" data-toggle="collapse" data-parent="#accordion1" href="#panelBody3">
                                            Integer sit amet odio id enim hendrerit volutpat vel sed dolor.
                                        </a>
                                    </h4>
                                </div>
                                <div id="panelBody3" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                                    the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                                    handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle1 collapsed" data-toggle="collapse" data-parent="#accordion1" href="#panelBody4">
                                            Integer sit amet odio id enim hendrerit volutpat vel sed dolor.
                                        </a>
                                    </h4>
                                </div>
                                <div id="panelBody4" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                                    the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                                    handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <Followus />
    <Footer />
    </React.Fragment>
  )
}

export default Sendmoneyabroad
