import React from 'react'

function Breadcrumbpage() {
  return (
    <div className="breadcrumb-main" data-aos="fade-down" data-aos-duration="1500">
        <div className="container">
            <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                <li className="breadcrumb-item active">Our Teams</li>
            </ol>
            </nav>
        </div>
    </div>
  )
}

export default Breadcrumbpage
