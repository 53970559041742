import React from "react";
import Marqueepagee from "../marquee/Marqueepagee";
import Breadcrumbpage from "../commonpage/Breadcrumbpage";
import Headertop from "../header/Headertop";
import Headermain from "../header/Headermain";
import Followus from "../footer/Followus";
import Footer from "../footer/Footer";
import { useState } from "react";
import { useEffect } from "react";
import { fetchOurServiceSlider } from "../../controler/ourservices/ourservices";
import { fetchGetStartedHomeStep } from "../../controler/getstarted/getstarted";
import { imageUrl } from "../../controler/ApiHelper";
function Buyforex() {
  // var url = "https://forex.weaddo.com";
  const [ourserviceslider, setOurServiceSlider] = useState([]);
  const [ourservicefaq, setOurServicefaq] = useState([]);
  const [getstartedhomestep, setGetStartedHomeStepData] = useState([]);
  const [active, setActive] = useState(0);

  useEffect(() => {
    async function ourserviceslider() {
      let buyforex = [];
      const data = await fetchOurServiceSlider();
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].title === "Buy Forex") {
          buyforex.push(data.data[i].faq_category);
        }
      }

      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].title === "Buy Forex") {
          setOurServiceSlider(data.data[i]);
        }
      }

      // setOurServiceSlider(data.data[buyforex]);
      setOurServicefaq(...buyforex);
      // console.log("faq chnagesssssssssssssssssssss", data.data[0].faq_category,buyforex)
    }
    ourserviceslider();
    // async function ourserviceslider () {
    //     let buyforex = []
    // 	const data = await fetchOurServiceSlider();
    //     for(let i=0;i<data.data.length;i++){
    //         if(data.data[i].title==="buyforex"){
    //             buyforex.push(data.data[i])
    //         }
    //     }
    //     for(let i=0; i<data.data.length;i++){
    //         if(data.data[i].title==="Buy Forex"){
    //             setOurServiceSlider(data.data[i]);
    //         }
    //     }

    // 	setOurServicefaq(data.data[0].faq_category);
    // 	console.log("our services data sddvdsvdvdf", data.data[0].faq_category);
    // }
    // ourserviceslider();

    async function getstartedhomestepData() {
      let array = [];
      const data = await fetchGetStartedHomeStep();
      // console.log("dataaaaaaaaaaaaaaaaaaaaaaaa",data.data);
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].pageName === "Buy Forex") {
          array.push(data.data[i]);
        }
      }
      setGetStartedHomeStepData(array);
    }
    getstartedhomestepData();
  }, []);
  const handleActive = (val) => {
    // console.log("valllllll",val);
    setActive(val);
  };
  return (
    <React.Fragment>
      <Headertop />
      <div className="innerbanner-main">
        <Headermain />
        <div id="myCarousel" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="item active">
              <img
                src={imageUrl + ourserviceslider.detailBannerImage}
                width="0"
                height="0"
                alt=""
                title=""
              />
              <div className="banner-content hidden-xs">
                <div className="homebannercontent">
                  <h1>{ourserviceslider.title}</h1>
                  <p>{ourserviceslider.overviewDesc}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Marqueepagee />
      <Breadcrumbpage />
      <div className="buforex-main" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <ul className="nav nav-tabs">
                <li className="active">
                  <a data-toggle="tab" href="#currencyexchange">
                    <img src="assets/images/form-icon1.png" alt="" title="" />{" "}
                    Currency Exchange
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#remittance">
                    <img src="assets/images/form-icon2.png" alt="" title="" />{" "}
                    Remittance
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#sendmoneyaboad">
                    <img src="assets/images/form-icon3.png" alt="" title="" />{" "}
                    Send Money Abroad
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#forexcard">
                    <img src="assets/images/form-icon3.png" alt="" title="" />{" "}
                    Travel Card
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#travelinsurance">
                    <img src="assets/images/form-icon3.png" alt="" title="" />{" "}
                    Travel Insurance
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-12">
              <div className="tab-content">
                <div id="currencyexchange" className="tab-pane fade in active">
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <input
                        type="text"
                        className="form-control inputcontrolcss"
                        placeholder="Full Name"
                      />
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <input
                        type="text"
                        className="form-control inputcontrolcss"
                        placeholder="Mobile No."
                      />
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <input
                        type="text"
                        className="form-control inputcontrolcss"
                        placeholder="E-Mail Address"
                      />
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <select className="form-control">
                        <option>Location</option>
                        <option>Delhi</option>
                        <option>Noida</option>
                        <option>Gurgaon</option>
                        <option>Meerut</option>
                        <option>Bijnor</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="radiobutton radiobuttoninner">
                        <div className="radiobuttonleft">
                          <div className="form__radio-group">
                            <input
                              type="radio"
                              name="cars"
                              id="large"
                              className="form__radio-input"
                              value="2"
                              checked
                            />
                            <label
                              className="form__label-radio form__radio-label"
                              for="large"
                            >
                              <span className="form__radio-button"></span> Buy
                              Forex
                            </label>
                          </div>
                        </div>
                        <div className="radiobuttonleft">
                          <div className="form__radio-group">
                            <input
                              type="radio"
                              name="cars"
                              id="small"
                              className="form__radio-input"
                              value="3"
                            />
                            <label
                              className="form__label-radio form__radio-label"
                              for="small"
                            >
                              <span className="form__radio-button"></span> Sell
                              Forex
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="Cars2" className="desc">
                      <div className="col-md-3 col-sm-6">
                        <div className="form-group">
                          <label>Check Rate</label>
                          <select className="form-control">
                            <option>One Currency Card</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="form-group">
                          <label>Currency</label>
                          <select className="form-control">
                            <option>Select Currency</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="form-group">
                          <label>Forex Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Forex Amount"
                          />
                          <img src="images/inr-icon.png" alt="" title="" />
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="form-group">
                          <label>INR Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Amount in INR"
                          />
                          <img src="images/inr-icon.png" alt="" title="" />
                        </div>
                      </div>
                      <div className="col-sm-12 text-right">
                        <a href="#!" className="btn btn-blue">
                          Buy Now
                        </a>
                      </div>
                    </div>
                    <div
                      id="Cars3"
                      className="desc"
                      style={{ display: "none" }}
                    >
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Currency</label>
                          <select className="form-control">
                            <option>Select Currency</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Forex Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Forex Amount"
                          />
                          <img src="images/inr-icon.png" alt="" title="" />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>INR Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Amount in INR"
                          />
                          <img src="images/inr-icon.png" alt="" title="" />
                        </div>
                      </div>
                      <div className="col-sm-12 text-right">
                        <a href="#!" className="btn btn-success">
                          <i className="fal fa-plus-circle"></i> Add
                          Currency/Product
                        </a>
                        <a href="#!" className="btn btn-blue">
                          Sell Now
                        </a>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-lg-offset-2 col-lg-8 col-sm-12 currentexchangediv">
                      <p>
                        Communication Details (You order confirmation details
                        will be sent on these contact details)
                      </p>
                      <div className="row">
                        <div className="col-sm-4">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Mobile No."
                          />
                        </div>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="E-Mail Address"
                          />
                        </div>
                        <div className="col-sm-4">
                          <a href="#!" className="btn btn-primary">
                            Get a Quote
                          </a>
                        </div>
                        <div className="col-sm-12">
                          <div className="radiobutton">
                            <div className="radiobuttonleftother">
                              <div className="form__radio-group">
                                <input
                                  type="radio"
                                  name="other"
                                  id="other"
                                  className="form__radio-input"
                                  value="3"
                                />
                                <label
                                  className="form__label-radio form__radio-label"
                                  for="other"
                                >
                                  <span className="form__radio-button"></span> I
                                  accept the <a href="#!">Privacy Policy</a>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="travelinsurance-main">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1 data-aos="fade-up" data-aos-duration="1500">
                {ourserviceslider.title}
              </h1>
              <p data-aos="fade-up" data-aos-duration="1500">
                <b>"{ourserviceslider.detailSubHeading}"</b>
              </p>
              <div className="travelinsurance-contant">
                <div
                  className="travelinsurance-contantleft"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <p>{ourserviceslider.detailDesc}</p>
                </div>
                <div
                  className="travelinsurance-contantright"
                  data-aos="fade-down"
                  data-aos-duration="1500"
                >
                  <img
                    src={imageUrl + ourserviceslider.detailImage}
                    alt=""
                    title=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="getstartednew-main aos-init aos-animate"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h3>Get Started</h3>
              <ul>
                {getstartedhomestep.map((getstartedhomesteppp) => {
                  return (
                    <li>
                      <img
                        src={imageUrl + getstartedhomesteppp.icon}
                        width="0"
                        height="0"
                        alt=""
                        title=""
                      />
                      <h4>{getstartedhomesteppp.text}</h4>
                      <p>{getstartedhomesteppp.desc}</p>
                    </li>
                  );
                })}
                {/* <li>
                                <img src="images/getstarted-icon1.png" width="0" height="0" alt="" title=""/>
                                <h4>STEP-1</h4>
                                <p>Select currency and denomination you need to carry along.</p>
                            </li>
                            <li>
                                <img src="images/getstarted-icon2.png" width="0" height="0" alt="" title=""/>
                                <h4>STEP-2</h4>
                                <p>Enter traveller details & delivery option doorstep delivery or brancg pick up.</p>
                            </li>
                            <li>
                                <img src="images/getstarted-icon3.png" width="0" height="0" alt="" title=""/>
                                <h4>STEP-3</h4>
                                <p>Receipt of forex  order completion at  blocked rate</p>
                            </li>
                            <li>
                                <img src="images/getstarted-icon3.png" width="0" height="0" alt="" title=""/>
                                <h4>STEP-4</h4>
                                <p>Receipt of forex  order completion at  blocked rate</p>
                            </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="hasslefree-main" data-aos="fade-up" data-aos-duration="1500">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h3 data-aos="fade-up" data-aos-duration="1500">Hassle Free Forex Solutions</h3>
                        <p data-aos="fade-up" data-aos-duration="1500">Great explorer of the truth, master-builder human happiness one rejects dislikes or avoids pleasure itself because 
                            pleasure but because those who do not know how to pursue rationally consequences that are extremely painful.</p>
                        <div className="hasslefree-div" data-aos="fade-up" data-aos-duration="1500">
                            <div className="hasslefree-leftdiv">
                                <ul>
                                    <li><span>Production technques (e.g. irrigation managment, recommended nitrogen inputs).</span></li>
                                    <li><span>Improving agricultural productivity in terms of quantity & quality.</span></li>
                                    <li><span>Minimizing the effects of pests (weeds, insects, pathogens, nematodes).</span></li>
                                </ul>
                            </div>
                            <div className="hasslefree-rightdiv" data-aos="fade-down" data-aos-duration="1500">
                                <img src="assets/images/hassle-img.jpg" alt='' title=''/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
      <div
        className="faq-main aos-init aos-animate"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-offset-2 col-lg-8">
              <h3>FAQ's</h3>
              <div className="panel-group" id="accordion1">
                {ourservicefaq.map((faq) => {
                  return (
                    <div className="panel">
                      <div
                        className="panel-heading"
                        onClick={() => handleActive(ourservicefaq.indexOf(faq))}
                      >
                        <h4 className="panel-title">
                          <a
                            className={
                              ourservicefaq.indexOf(faq) === active
                                ? "accordion-toggle1"
                                : "accordion-toggle1 collapsed"
                            }
                            data-toggle="collapse"
                            data-parent="#accordion1 "
                            href="#panelBody1"
                          >
                            {faq.title}
                          </a>
                        </h4>
                      </div>
                      <div
                        id={ourservicefaq.indexOf(faq)}
                        className={
                          ourservicefaq.indexOf(faq) === active
                            ? "panel-collapse collapse in"
                            : "panel-collapse collapse"
                        }
                      >
                        <div className="panel-body">
                          <p>{faq.description}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* <div className="panel">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle1" data-toggle="collapse" data-parent="#accordion1 " href="#panelBody1">Nam imperdiet sem in fringilla placerat.</a>
                                    </h4>
                                </div>
                                <div id="panelBody1" className="panel-collapse collapse in">
                                    <div className="panel-body">
                                        <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                            the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                            handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle1 collapsed" data-toggle="collapse" data-parent="#accordion1" href="#panelBody2">
                                            Integer sit amet odio id enim hendrerit volutpat vel sed dolor.
                                        </a>
                                    </h4>
                                </div>
                                <div id="panelBody2" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                                    the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                                    handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle1 collapsed" data-toggle="collapse" data-parent="#accordion1" href="#panelBody3">
                                            Integer sit amet odio id enim hendrerit volutpat vel sed dolor.
                                        </a>
                                    </h4>
                                </div>
                                <div id="panelBody3" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                                    the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                                    handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a className="accordion-toggle1 collapsed" data-toggle="collapse" data-parent="#accordion1" href="#panelBody4">
                                            Integer sit amet odio id enim hendrerit volutpat vel sed dolor.
                                        </a>
                                    </h4>
                                </div>
                                <div id="panelBody4" className="panel-collapse collapse">
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                                    the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                                    handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Followus />
      <Footer />
    </React.Fragment>
  );
}

export default Buyforex;
