import Endpoints from "../Endpoints";
import { baseUrl } from "../ApiHelper";

export const fetchEnquiryForm = async (jsonData) => {
    const data = await fetch(`${baseUrl}/${Endpoints.enquiryform}`, {
    method: 'POST', 
    headers: {
        'Content-Type': 'application/json'
      },
    mode: 'cors', 
    body: JSON.stringify(jsonData)

  });

  console.log(" data", data)
    return await data.json();
};