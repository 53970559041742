import Endpoints from "../Endpoints";
import { baseUrl } from "../ApiHelper";


export const fetchRemittanceContant = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.remittancecontent}`);
    return await data.json();
};

export const fetchCityFormList = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.cityformlist}`);
    return await data.json();
};


export const fetchRemittanceForm = async (jsonData) => {console.log("1111111111111");
    const data = await fetch(`${baseUrl}/${Endpoints.remittanceform}`, {
    method: 'POST', 
    headers: {
        'Content-Type': 'application/json'
      },
    mode: 'cors', 
    body: JSON.stringify(jsonData)
  });
  console.log("..................22222222",data.json);

    return await data.json();
};