import React, { useEffect, useState } from "react";
import { fetchFollowUs } from "../../controler/footer/footer";
import { Link, useNavigate } from 'react-router-dom'

function Followus () {

    const[followus, setFollowUs] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
    async function followus(){
        const data = await fetchFollowUs();
        setFollowUs(data.data);
    }
    followus();
}, [])
    return(
        <div className="followus-main aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="followus-div">
                            <p>Follow Us </p>
                            <ul>
                                {/* <a onClick={() => navigate('privacy-policy')}>Privacy Policy</a>
                                <a onClick={() => navigate('return-policy')}>Return Policy</a>
                                <a onClick={() => navigate('terms-and-condition')}>Terms and condition</a> */}
                                {/* {followus.map(followuslist => {
                                    return(
                                    <li key={followus.indexOf(followuslist)}><a href={followuslist.icon_url} onClick={(e) => { e.preventDefault(); window.open(followuslist.icon_url, '_blank'); }}><i className={followuslist.icon_alt}></i></a></li>
                                    )
                                })} */}
                                <li><a href="https://www.facebook.com/quickforex" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://www.instagram.com/quickforex" target="_blank"><img src="/assets/images/insta.png" width={25}/></a></li>
                                {/* <li><a href="#!"><i className="fab fa-pinterest-p"></i></a></li>
                                <li><a href="#!"><i className="fab fa-linkedin-in"></i></a></li> */}
                                <li><a href="https://twitter.com/quick_forex" target="_blank"><img src="/assets/images/twitter-logo.png" width={25}/> </a></li>

{/* <li><a href={followuslist.slug}><i className={"fab " +  followuslist.icon_alt}></i></a></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="followus-div newlinks">
                            <a onClick={() => navigate('/privacy-policy')}>Privacy Policy |</a>
                                <a onClick={() => navigate('/return-policy')}> Return Policy |</a>
                                <a onClick={() => navigate('/terms-and-condition')}> Terms and condition </a>
                                {/* {followus.map(followuslist => {
                                    return(
                                    <li key={followus.indexOf(followuslist)}><a href={followuslist.icon_url} onClick={(e) => { e.preventDefault(); window.open(followuslist.icon_url, '_blank'); }}><i className={followuslist.icon_alt}></i></a></li>
                                    )
                                })} */}
                               
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Followus