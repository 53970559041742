import React, { useEffect, useState } from 'react'
// import { fetchCopyRight, fetchFooterMain, fetchGetInTouch } from '../../controler/home/Home';
import { Link } from 'react-router-dom';
import { fetchCopyRight, fetchFooterMain, fetchGetInTouch } from '../../controler/footer/footer';

function Footer() {
	const[footermain, setFooterMain] = useState([]);
	const[getintouch, setGetInTouch] = useState([]);
	const[copyright, setCopyRight] = useState([]);

	useEffect(() => {
	async function footermain(){
		const data = await fetchFooterMain();
		setFooterMain(data.data);
	}
	footermain();

	async function getintouch(){
		const data = await fetchGetInTouch();
		setGetInTouch(data.data[0]);
	}
	getintouch();

	async function copyright(){
		const data = await fetchCopyRight();
		setCopyRight(data.data[0]);
	}
	copyright();

	}, [])
    return(
        <div className="footer-main aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
			<div className="container">
				<div className="row">
					{/* {footermain.map(footermainn => {
						return(
							<div key={footermain.indexOf(footermainn)} className="col-sm-3 col-xs-6" data-aos="fade-up" data-aos-duration="1500">
								<h4>{footermainn.footerTitle}</h4>
								<ul>
									{footermainn.footerCategory.map(footerlinksss => {
										return(
											<li key={footermainn.footerCategory.indexOf(footerlinksss)}><Link to={footerlinksss.slug}>{footerlinksss.heading}</Link></li>
										)
									})}
								</ul>
							</div>
						)
					})} */}
					{/* <div className="col-sm-3 col-xs-6" data-aos="fade-up" data-aos-duration="1500">
						<h4>{footermain.footerTitle}</h4>
						<ul>
							{footerlinks.map(footerlinksss => {
								return(
									<li><Link to={footerlinksss.slug}>{footerlinksss.heading}</Link></li>
								)
							})}
							<li><Link to="/about">About us</Link></li>
							<li><Link to="/ourteam">Meet the team</Link></li>
							<li><Link to="/contact">Our Branches</Link></li>
							<li><Link to="/awards">Certifications</Link></li>
						</ul>
					</div>
					<div className="col-sm-3 col-xs-6" data-aos="fade-down" data-aos-duration="1500">
					<h4>{footermain.footerTitle}</h4>
						<ul>
							<li><Link to="/buyforex">Buy Forex</Link></li>
							<li><Link to="/Sellforex">Sell Forex</Link></li>
							<li><Link to="/home">Currency Exchange</Link></li>
							<li><Link to="#!">Send Money Abroad</Link></li>
							<li><Link to="/travelcard">Forex Card</Link></li>
							<li><Link to="/travelinsurance">Travel Insurance</Link></li>
							<li><Link to="/remittance">Remittance</Link></li>
							
						</ul>
					</div>
					<div className="col-sm-3 col-xs-6" data-aos="fade-down" data-aos-duration="1500">
					<h4>{footermain.footerTitle}</h4>
						<ul>
							<li><Link to="/faq">FAQs</Link></li>
							<li><Link to="/blog">Blogs</Link></li>
							<li><Link to="/home">Forex Rates</Link></li>
							<li><Link to="/home">Currency Converter</Link></li>
							<li><Link to="/contact">Contact Us</Link></li>
						</ul>
					</div> */}
					<div className="col-sm-12 col-xs-12 text-center" data-aos="fade-up" data-aos-duration="1500">
						<h4>Get in Touch</h4>
						<p><b>Email:</b> {getintouch.email}</p>
						<p><b>Phone:</b>  {getintouch.phone}</p>
						<p><b>Landline:</b> 011-42258182</p>
						<p>{getintouch.address}</p>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-12">
						<h6>{copyright.text}</h6>
					</div>
				</div>
			</div>
		</div>
    )
}

export default Footer