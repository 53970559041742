import Endpoints from "../Endpoints";
import { baseUrl } from "../ApiHelper";


export const fetchTestimonialHomePage = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.testimonialhomepage}`);
    return await data.json();
};

export const fetchTestimonialSlider = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.testimonialslider}`);
    return await data.json();
};