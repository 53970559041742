import Endpoints from "../Endpoints";
import { baseUrl } from "../ApiHelper";

export const fetchAboutPage = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.aboutpage}`);
    return await data.json();
};

export const fetchJourney = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.journey}`);
    return await data.json();
};