import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';
import About from '../about/About';
import Awards from "../awards/Awards";
import Contactus from "../contact/Contactus";
import Faqpage from "../faq/Faqpage";
import Ourservices from "../ourservices/Ourservices";
import Ourteam from "../ourteam/Ourteam";
import Blog from "../blog/Blog";
import Blogdetails from "../blog/Blogdetails";
import Remittance from "./Remittance";
import Buyforex from "./Buyforex";
import Sellforex from "./Sellforex";
import Travelcard from "./Travelcard";
import Travelinsurance from "./Travelinsurance";
// import Slider from "./Slider";
import Homebanner from "../banner/Homebanner";
// import Withoutreactform from "../Withoutreactform"
// import Innerbanner from "./Innerbanner";
// import Otherformdemo from "./Otherformdemo";
import Aos from "aos";
import 'aos/dist/aos.css'
import Formmainpage from "../formvalidationfixed/Formmainpage";
import Sendmoneyabroad from "./Sendmoneyabroad";
import ReturnPloicy from "../footer/ReturnPolicy";
import PrivacyPolicy from "../footer/PrivacyPolicy";
import TermsAndCondition from "../footer/TermsAndCondition";

function Routerpage() {
    useEffect(() => {
        Aos.init();
    })
    return(
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/awards' element={<Awards />} />
                <Route path='/contact' element={<Contactus />} />
                <Route path="/faq"  element={<Faqpage />} />
                <Route path="/ourservices" element={<Ourservices />} />
                <Route path="/ourteam" element={<Ourteam />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blogdetails" element={<Blogdetails />} />
                <Route path="/buyforex" element={<Buyforex />} />
                <Route path="/sellforex" element={<Sellforex />} />
                <Route path="/remittance" element={<Remittance />} />
                <Route path="/travelcard" element={<Travelcard />} />
                <Route path="/travelinsurance" element={<Travelinsurance />} />
                {/* <Route path="/slider" element={<Slider />} /> */}
                <Route path="/homebanner" element={<Homebanner />} />
                <Route path="/formmainpage" element={<Formmainpage />} />
                <Route path="/sendmoneyabroad" element={<Sendmoneyabroad />} />
                {/* <Route path="/withoutreactform" element={<Withoutreactform />} /> */}
                {/* <Route path="/innerbanner" element={<Innerbanner />} /> */}
                {/* <Route path="/" element={<Otherformdemo />} /> */}
                <Route path="/return-policy" element={<ReturnPloicy />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-and-condition" element={<TermsAndCondition />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Routerpage