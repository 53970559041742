import React, { useEffect, useState } from "react";
import Followus from "../footer/Followus";
import Footer from "../footer/Footer";
import Breadcrumbpage from "../commonpage/Breadcrumbpage";
import Headertop from "../header/Headertop";
import Headermain from "../header/Headermain";
import Contactform from "../commonpage/Contactform";
import { fetchContactPageCityData, fetchContactTabList, fetchContactTopContent } from "../../controler/contact/contact";
import { imageUrl } from "../../controler/ApiHelper";
function Contactus(){

	const[contacttopcontent, setContactTopContent] = useState([])
	// const[contactcitydeatil, setContactCityDetail] = useState([])
	const[contacttablist, setContactTabList] = useState([])
	const[contactpagecitydata, setContactPageCityData] =useState([])
	
	// const[writetous, setWriteToUs] = useState({
	// 	username :"",
	// 	email :"",
	// 	mobile :"",
	// });
	// const [record, setRecord] = useState([]);
	// const[nameError, setNameError] = useState(false);
	// const handleInput = (e) => {
	// 	console.log("userrrrrrrrrr",e.target.name,e.target.value ,e.target.value.length);
	// 	const name = e.target.name;
	// 	const value = e.target.value;
	// 	if(e.target.value.length < 4)
	// 	{
	// 		setNameError(true)
	// 	} else(
	// 		setNameError(false)
	// 	)
	// 	setWriteToUs({ ...writetous, [name] : value});
	// };

	// const handleSubmit = (e) =>{
	// 	e.preventDefault();

	// 	const newRecord = { ...writetous, id:new Date().getTime().toString()}
	// 	console.log(newRecord)
	// 	setRecord([...record, newRecord])
	// }

	// var url="https://forex.weaddo.com";
	useEffect(() => {
		async function contacttopcontent(){
			const data = await fetchContactTopContent();
			setContactTopContent(data.data[0]);
		}
		contacttopcontent();

		// async function contactcitydeatil(){
		// 	const data = await fetchContactCityDetail();
		// 	setContactCityDetail(data.data);
		// 	console.log('contact city details', data.data);
		// }
		// contactcitydeatil()

		async function contacttablist(){
			const data = await fetchContactTabList();
			setContactTabList(data.data);
			// console.log('contact tab List', data.data);
		}
		contacttablist()

		async function contactpagecitydata() {
			const data = await fetchContactPageCityData();
			setContactPageCityData(data.data);
			// console.log("contact page city data", data.data);
		}
		contactpagecitydata();
	},[])

	async function handleClick(city){
		const data = await fetchContactPageCityData(city);
		// console.log("dataaaaaaaaaaaaaaaaaa",data);
			setContactPageCityData(data.data);
	}
	
    return(
        <React.Fragment>
            <Headertop />
			<div className="innerbanner-main">
				<Headermain />
				<div id="myCarousel" className="carousel slide" data-ride="carousel">
					<div className="carousel-inner">
						{contacttopcontent.length !== 0 ? (
						<div className="item active">
							<img src={imageUrl+contacttopcontent.bannerImg} width="0" height="0" alt="" title=""/>
							<div className="banner-content hidden-xs">
								<div className="homebannercontent">
									<h1>{contacttopcontent.title}</h1>
									<p>{contacttopcontent.heading}</p>
								</div>
							</div>
						</div>
						) : (
							<div className="item active">
							<img src="assets/images/blog-banner.jpg" width="0" height="0" alt="" title=""/>
							<div className="banner-content hidden-xs">
								<div className="homebannercontent">
									<h1>{contacttopcontent.title}</h1>
									<p>{contacttopcontent.heading}</p>
								</div>
							</div>
						</div>
						)}
					</div>
				</div>
			</div>
            <Breadcrumbpage />
			<div className="findushere-main" data-aos="fade-up" data-aos-duration="1500">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<h2 data-aos="fade-up" data-aos-duration="1500">{contacttopcontent.title}</h2>
							<ul className="nav nav-tabs" data-aos="fade-up" data-aos-duration="1500">
								{contacttablist.map(contacttablistt => {
									return(
										<li key={contacttablist.indexOf(contacttablistt)} onClick={()=>handleClick(contacttablistt.city)} className={contacttablist.indexOf(contacttablistt) === 0? "li active" : "li"}><a data-toggle="tab" href="#all">{contacttablistt.city}</a></li>
									)
								})}
								{/* <li className="active"><a data-toggle="tab" href="#all">All</a></li>
								<li><a data-toggle="tab" href="#gujrat">Gujrat</a></li>
								<li><a data-toggle="tab" href="#kolkata">Kolkata</a></li>
								<li><a data-toggle="tab" href="#delhi">Delhi</a></li>
								<li><a data-toggle="tab" href="#up">Uttar Pradesh</a></li>
								<li><a data-toggle="tab" href="#panjab">Punjab</a></li> */}
							</ul>
						
							<div className="tab-content">
							<div id="all" className="tab-pane fade in active">
									<div className="row">
										{contactpagecitydata.map(contactpagecitydataaa => {
											return(
												<div key={contactpagecitydata.indexOf(contactpagecitydataaa)} className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1500">
													<h3>{contactpagecitydataaa.heading}</h3>
													<p>{contactpagecitydataaa.description}
													</p>
													<ul>
														<li><img src="assets/images/call-icon.png" width="0" height="0" alt="" title=""/> {contactpagecitydataaa.mobile}</li>
														<li><img src="assets/images/email-icon.png" width="0" height="0" alt="" title=""/> {contactpagecitydataaa.email}</li>
														<li><img src="assets/images/map-icon.png" width="0" height="0" alt="" title=""/> {contactpagecitydataaa.location}</li>
													</ul>
												</div>
											)
										})}
										
										{/* <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1500">
											<h3>Connaught Place</h3>
											<p>Flat- no 104, Arunchal building, 19- barakhamba road New Delhi-110001
											</p>
											<ul>
												<li><img src="assets/images/call-icon.png" width="0" height="0" alt="" title=""/> 1800-121-004005</li>
												<li><img src="assets/images/email-icon.png" width="0" height="0" alt="" title=""/> online@quickforex.in</li>
												<li><img src="assets/images/map-icon.png" width="0" height="0" alt="" title=""/> Get Directions</li>
											</ul>
										</div>
										<div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1500">
											<h3>Connaught Place</h3>
											<p>Flat- no 104, Arunchal building, 19- barakhamba road New Delhi-110001
											</p>
											<ul>
												<li><img src="assets/images/call-icon.png" width="0" height="0" alt="" title=""/> 1800-121-004005</li>
												<li><img src="assets/images/email-icon.png" width="0" height="0" alt="" title=""/> online@quickforex.in</li>
												<li><img src="assets/images/map-icon.png" width="0" height="0" alt="" title=""/> Get Directions</li>
											</ul>
										</div>
										</div>
										<hr />
										<div className="row">
										<div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1500">
											<h3>Delhi Head Office</h3>
											<p>head Office: 3507/6, 3rd Floor, kirti Sadan Building, pyarelal Road, Karol Bagh,
												new Delhi- 110005
											</p>
											<ul>
												<li><img src="assets/images/call-icon.png" width="0" height="0" alt="" title=""/> 1800-121-004005</li>
												<li><img src="assets/images/email-icon.png" width="0" height="0" alt="" title=""/> online@quickforex.in</li>
												<li><img src="assets/images/map-icon.png" width="0" height="0" alt="" title=""/> Get Directions</li>
											</ul>
										</div>
										<div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1500">
											<h3>Connaught Place</h3>
											<p>Flat- no 104, Arunchal building, 19- barakhamba road New Delhi-110001
											</p>
											<ul>
												<li><img src="assets/images/call-icon.png" width="0" height="0" alt="" title=""/> 1800-121-004005</li>
												<li><img src="assets/images/email-icon.png" width="0" height="0" alt="" title=""/> online@quickforex.in</li>
												<li><img src="assets/images/map-icon.png" width="0" height="0" alt="" title=""/> Get Directions</li>
											</ul>
										</div>
										<div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1500">
											<h3>Connaught Place</h3>
											<p>Flat- no 104, Arunchal building, 19- barakhamba road New Delhi-110001
											</p>
											<ul>
												<li><img src="assets/images/call-icon.png" width="0" height="0" alt="" title=""/> 1800-121-004005</li>
												<li><img src="assets/images/email-icon.png" width="0" height="0" alt="" title=""/> online@quickforex.in</li>
												<li><img src="assets/images/map-icon.png" width="0" height="0" alt="" title=""/> Get Directions</li>
											</ul>
										</div> */}
									</div>
								</div>
								{/* <div id="delhi" className="tab-pane fade">
									<div className="row">
										<div className="col-sm-4" data-aos="fade-up" data-aos-duration="1500">
											<h3>Connaught Place</h3>
											<p>Flat- no 104, Arunchal building, 19- barakhamba road New Delhi-110001
											</p>
											<ul>
												<li><img src="assets/images/call-icon.png" width="0" height="0" alt="" title=""/> 1800-121-004005</li>
												<li><img src="assets/images/email-icon.png" width="0" height="0" alt="" title=""/> online@quickforex.in</li>
												<li><img src="assets/images/map-icon.png" width="0" height="0" alt="" title=""/> Get Directions</li>
											</ul>
										</div>
									</div>
								</div>
								<div id="gujrat" className="tab-pane fade">
									<div className="row">
										<div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1500">
											<h3>Gujrat</h3>
											<p>Flat- no 104, Arunchal building, 19- barakhamba road New Delhi-110001
											</p>
											<ul>
												<li><img src="assets/images/call-icon.png" width="0" height="0" alt="" title=""/> 1800-121-004005</li>
												<li><img src="assets/images/email-icon.png" width="0" height="0" alt="" title=""/> online@quickforex.in</li>
												<li><img src="assets/images/map-icon.png" width="0" height="0" alt="" title=""/> Get Directions</li>
											</ul>
										</div>
									</div>
								</div>
								<div id="kolkata" className="tab-pane fade">
									<div className="row">
										<div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1500">
											<h3>Kolkata</h3>
											<p>Flat- no 104, Arunchal building, 19- barakhamba road New Delhi-110001
											</p>
											<ul>
												<li><img src="assets/images/call-icon.png" width="0" height="0" alt="" title=""/> 1800-121-004005</li>
												<li><img src="assets/images/email-icon.png" width="0" height="0" alt="" title=""/> online@quickforex.in</li>
												<li><img src="assets/images/map-icon.png" width="0" height="0" alt="" title=""/> Get Directions</li>
											</ul>
										</div>
									</div>
								</div>
								<div id="up" className="tab-pane fade">
									<div className="row">
										<div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1500">
											<h3>Meerut</h3>
											<p>Flat- no 104, Arunchal building, 19- barakhamba road New Delhi-110001
											</p>
											<ul>
												<li><img src="assets/images/call-icon.png" width="0" height="0" alt="" title=""/> 1800-121-004005</li>
												<li><img src="assets/images/email-icon.png" width="0" height="0" alt="" title=""/> online@quickforex.in</li>
												<li><img src="assets/images/map-icon.png" width="0" height="0" alt="" title=""/> Get Directions</li>
											</ul>
										</div>
									</div>
								</div>
								<div id="panjab" className="tab-pane fade">
									<div className="row">
										<div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-duration="1500">
											<h3>Amirtshar</h3>
											<p>Flat- no 104, Arunchal building, 19- barakhamba road New Delhi-110001
											</p>
											<ul>
												<li><img src="assets/images/call-icon.png" width="0" height="0" alt="" title=""/> 1800-121-004005</li>
												<li><img src="assets/images/email-icon.png" width="0" height="0" alt="" title=""/> online@quickforex.in</li>
												<li><img src="assets/images/map-icon.png" width="0" height="0" alt="" title=""/> Get Directions</li>
											</ul>
										</div>
									</div>
								</div> */}
							</div>
						</div>
					</div>
					<div className="row" data-aos="fade-up" data-aos-duration="1500">
						<div className="col-sm-12">
							<iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14006.474346419272!2d77.19861502944255!3d28.64119131862802!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd44c7bac521%3A0xc0804a0faf236bba!2sQuick%20Forex%20Limited!5e0!3m2!1sen!2sin!4v1679382545406!5m2!1sen!2sin" 
										style={{width:"100%", height:"400px", border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
						</div>
					</div>
				</div>
			</div>
			<Contactform />
			{/* <div className="writetous" data-aos="fade-up" data-aos-duration="1500">
				<div className="container">
					<div className="row">
						<div className="col-lg-offset-2 col-lg-8 col-md-12">
							<h3 data-aos="fade-up" data-aos-duration="1500">Write to Us</h3>
							<form onSubmit={handleSubmit}>
							<div className="row">
								<div className="col-sm-4">
									<div className="form-group">
										<input type="text" autoComplete="off" 
										value={writetous.username}
										onChange={handleInput}
										name="username" className="form-control" placeholder="Full Name" />

										{nameError ? <span style={{color:'#f00'}}>Name lenght must be  4 charecter</span> : ''}
									</div>
								</div>
								<div className="col-sm-4">
									<div className="form-group">
										<input type="text" autoComplete="off"
										value={writetous.mobile}
										onChange={handleInput}
										 name="mobile" className="form-control" placeholder="Mobile No." />
									</div>
								</div>
								<div className="col-sm-4">
									<div className="form-group">
										<input type="text" autoComplete="off" 
										value={writetous.email}
										onChange={handleInput}
										name="email" className="form-control" placeholder="E-mail Address" />
									</div>
								</div>
								<div className="col-sm-12">
									<div className="form-group">
										<textarea className="form-control"name="textarea" style={{height:"150px"}} placeholder="Write Your Comments"></textarea>
									</div>
								</div>
							<div className="form-group">
								<button type="submit" className="btn btn-blue">Submit</button>
							</div>
						</div>
						</form>
						</div>
					</div>
				</div>
			</div> */}
			<Followus />
			<Footer />
        </React.Fragment>
    )
}
export default Contactus