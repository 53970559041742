import Endpoints from "../Endpoints";
import { baseUrl } from "../ApiHelper";

export const fetchContactTopContent = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.contacttopcontent}`);
    return await data.json();
};

export const fetchContactCityDetail = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.contactcitydetail}`);
    return await data.json();
};

export const fetchContactTabList = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.contacttablist}`);
    return await data.json();
};

export const fetchContactPageCityData = async (city) => {
    const data = await fetch(`${baseUrl}/${Endpoints.contactpagecitydata}/${city}`);
    return await data.json();
};

export const fetchContactForm = async (jsonData) => {
    const data = await fetch(`${baseUrl}/${Endpoints.contactform}`, {
    method: 'POST', 
    headers: {
        'Content-Type': 'application/json'
      },
    mode: 'cors', 
    body: JSON.stringify(jsonData)

  });
    return await data.json();
};