import Endpoints from "../Endpoints";
import { baseUrl } from "../ApiHelper";

export const fetchLogo = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.logo}`);
    return await data.json();
};

export const fetchHeaderMenu = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.headermenu}`);
    return await data.json();
};

export const fetchHeaderTop = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.headertop}`);
    return await data.json();
};

export const fetchHeaderHamburgerMenu = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.headerhamburgermenu}`);
    return await data.json();
};

export const fetchHamburgerServices = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.hamburgerservices}`);
    return await data.json();
};