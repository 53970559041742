import Endpoints from "../Endpoints";
import { baseUrl } from "../ApiHelper";

export const fetchOurServiceBanner = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.ourservicebanner}`);
    return await data.json();
};

export const fetchOurServiceHome = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.ourservicehome}`);
    return await data.json();
};

export const fetchOurServiceSlider = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.ourserviceslider}`);
    return await data.json();
};