const Endpoints={
    logo : "logo/general",
    headertop : "header/topheader",
    headermenu : "header/category",
    banner : "banner/general",
    home : "home/general",
    whyquickforex : "why-quick-forex/general",
    whyquickforexicon : "forexIcon/manage",
   ourservicehome : "ourservices/general",
   ourserviceslider : "serviceOverview/manage",
   getstartedhome : "getStarted/general",
   getstartedhomestep : "getStartedIcon/manage",
   awardbanner : "award/general",
   awardlist : "award/category",
   aboutpage: "aboutus/general",
   ourteam: "ourteam/manage",
   ourteambanner: "ourteam/general",
   testimonialhomepage: "testimonial/general",
   testimonialslider: "testimonial/manage",
   contacttopcontent: "contactUs/general",
   contactcitydetail: "contactUs/manage",
   contacttablist: "contactUs/category",
   faqbanner: "faq/general",
   faqlist: "faq/manage",
   blogbanner: "blogs-general/manage",
   bloggrid: "blogs/manage",
   journey: "journey/manage",
   footermain: "footer/manage",
   followus: "followus/manage",
   getintouch: "getInTouch/manage",
   footerlinks: "footerCategory/manage",
   Sidesticky: "floatingIcons/manage",
   headerhamburgermenu: "header/hambergerMenu",
   headerhamburgerservicemenu: "header/ourServices",
   headerhamburgermenuheading: "header/ourServicesGeneral",
   contactform: "contactForm/add",
   copyright: "copyright/general",
   contactpagecitydata: "contactUs/city",
   newsletterform: "newsLetterForm/add",
   remittancecontent: "remittanceService/general",
   travelcard: "travelCardCategory/general",
   travelfeatureheading: "travelCardFeature/general",
   travelfeature: "travelCardFeature/category",
   travelcardform: "travelCardForm/add",
   sendmoneyabroadform: "sendMoneyAbroad/add",
   selectcurrency: "currency/general",
   remittanceform: "remittanceForm/add",
   cityformlist: "city/general",
   hamburgerservices: "header/ourServices",
   enquiryform :"callBackform/add"

}
export default Endpoints