import React, { useEffect, useState } from 'react'
import Breadcrumbpage from '../commonpage/Breadcrumbpage'
import Followus from '../footer/Followus'
import Footer from "../footer/Footer";
import Marqueepagee from '../marquee/Marqueepagee'
import Headertop from '../header/Headertop';
import Headermain from '../header/Headermain';
import { fetchBlogBanner, fetchBlogGrid } from '../../controler/blog/blog';
import { imageUrl } from '../../controler/ApiHelper';

function Blog(){
    // var url = "http://localhost:8070";
    const[blogbanner, setBlogBanner] = useState([])
    const[bloggrid, setBlogGrid] = useState([])

    useEffect(() => {
        async function blogbanner(){
            const data = await fetchBlogBanner();
            setBlogBanner(data.data[0]);
        }
        blogbanner();

        async function bloggrid(){
            const data = await fetchBlogGrid();
            setBlogGrid(data.data);
        }
        bloggrid();
    }, [])

    return(
        <React.Fragment>
            <Headertop />
		<div className="innerbanner-main">
				<Headermain />
				<div id="myCarousel" className="carousel slide" data-ride="carousel">
					<div className="carousel-inner">
						<div className="item active">
						<img src={imageUrl+blogbanner.bannerImg} width="0" height="0" alt="" title=""/>
						<div className="banner-content hidden-xs">
							<div className="homebannercontent">
								<h1>{blogbanner.title}</h1>
								<p>{blogbanner.description}</p>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
            <Marqueepagee />
            <Breadcrumbpage />
            <div className="latestblog-main">
                <div className="container">
                    <div className="row">
                        <div className="grid-container">
                            {bloggrid.map(bloggridmain => {
                                return(
                                    <div className="grid-item" data-aos="fade-up" data-aos-duration="1500">
                                        <div className="latestblog-div">
                                            <a href='/blogdetails'>
                                                <img src={imageUrl+bloggridmain.blogsThumbnailImage} width="0" height="0" alt="" title=""/>
                                                <h3>{bloggridmain.title}</h3>
                                                <p>{bloggridmain.description}</p>
                                                <h6>{bloggridmain.date}</h6>
                                            </a>
                                        </div>
                                    </div>
                                )
                            })}
                            
                            {/* <div className="grid-item" data-aos="fade-up" data-aos-duration="1500">
                                <div className="latestblog-div">
                                    <img src="assets/images/blog-img2.jpg" width="0" height="0" alt="" title=""/>
                                    <h3>Cryptocurrency By Venanda</h3>
                                    <p>Complete account of the system, expound the actual teachings of the great explorer....</p>
                                    <h6>17 November 2018</h6>
                                </div>
                            </div>
                            <div className="grid-item" data-aos="fade-up" data-aos-duration="1500">
                                <div className="latestblog-div">
                                    <img src="assets/images/blog-img2.jpg" width="0" height="0" alt="" title=""/>
                                    <h3>Cryptocurrency By Venanda</h3>
                                    <p>Complete account of the system, expound the actual teachings of the great explorer....</p>
                                    <h6>17 November 2018</h6>
                                </div>
                            </div>
                            <div className="grid-item" data-aos="fade-up" data-aos-duration="1500">
                                <div className="latestblog-div">
                                    <img src="assets/images/blog-img1.jpg" width="0" height="0" alt="" title=""/>
                                    <h3>Cryptocurrency By Venanda</h3>
                                    <p>Complete account of the system, expound the actual teachings of the great explorer </p>
                                    <h6>17 November 2018</h6>
                                </div>imageUrl
                            </div>
                            <div className="grid-item" data-aos="fade-up" data-aos-duration="1500">
                                <div className="latestblog-div">
                                    <img src="assets/images/blog-img4.jpg" width="0" height="0" alt="" title=""/>
                                    <h3>Cryptocurrency By Venanda</h3>
                                    <p>Complete account of the system, expound the actual teachings of the great explorer....</p>
                                    <h6>17 November 2018</h6>
                                </div>
                            </div>
                            <div className="grid-item" data-aos="fade-up" data-aos-duration="1500">
                                <div className="latestblog-div">
                                    <img src="assets/images/blog-img3.jpg" width="0" height="0" alt="" title=""/>
                                    <h3>Cryptocurrency By Venanda</h3>
                                    <p>Complete account of the system, expound the actual teachings of the great explorer....</p>
                                    <h6>17 November 2018</h6>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Followus />
            <Footer />
        </React.Fragment>
    )
}
export default Blog