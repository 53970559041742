import React from "react";
import Followus from "../footer/Followus";
import Footer from "../footer/Footer";
import Marqueepagee from "../marquee/Marqueepagee";
import Breadcrumbpage from "../commonpage/Breadcrumbpage";
import Headertop from "../header/Headertop";
import Headermain from "../header/Headermain";
import { useState } from "react";
import { useEffect } from "react";
import { fetchOurTeam, fetchOurTeamBanner } from "../../controler/ourteam/ourteam";
import { toast } from "react-toastify";
import { imageUrl } from "../../controler/ApiHelper";

function Ourteam (){
    // var url = "https://forex.weaddo.com";
    const[ourteam, setOurTeam] = useState([])
    const[ourteambanner, setOurTeambanner] = useState([])

    useEffect(() => {
        async function ourteam(){
            const data = await fetchOurTeam();
            setOurTeam(data.data);
            console.log("our Team page", data.data);
        }
        ourteam();
        async function ourteambanner(){
            const data = await fetchOurTeamBanner();
            if(data.success === true){
                setOurTeambanner(data.data[0]);
                console.log("text shoing", data.data);
            } else{
                toast.error('Api Error Occured')
                console.log("text arror", data.data);
            }
            
        }
        ourteambanner();

    }, [])

    return(
        <React.Fragment>
            <Headertop />
		<div className="innerbanner-main">
				<Headermain />
				<div id="myCarousel" className="carousel slide" data-ride="carousel">
					<div className="carousel-inner">
						<div className="item active">
						<img src={imageUrl+ourteambanner.bannerImg} width="0" height="0" alt="" title=""/>
						<div className="banner-content hidden-xs">
							<div className="homebannercontent">
								<h1>{ourteambanner.title}</h1>
								<p>{ourteambanner.description}</p>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
            <Marqueepagee />
            <Breadcrumbpage />
            <div className="ourteampage-main" data-aos="fade-up" data-aos-duration="1500">
                <div className="container">
                    <div className="row">
                        {ourteam.map(ourteamm => {
                            return(
                                <div className="col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="500">
                                    <div className="ourteam-div">
                                        <img src={imageUrl+ourteamm.teamImage} width="0" height="0" alt="" title=""/>
                                        <h4>{ourteamm.title}</h4>
                                        <p>{ourteamm.designation} </p>
                                    </div>
                                </div>
                            )
                        })}
                        
                        {/* <div className="col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="500">
                            <div className="ourteam-div">
                                <img src="assets/images/ourteam-img2.jpg" width="0" height="0" alt="" title=""/>
                                <h4>Mr. Satish Sharma</h4>
                                <p>CEO, Founder, Quick Forex </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="500">
                            <div className="ourteam-div">
                                <img src="assets/images/ourteam-img3.jpg" width="0" height="0" alt="" title=""/>
                                <h4>Mrs. Pratima Sharma</h4>
                                <p>CEO, Founder, Quick Forex </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="500">
                            <div className="ourteam-div">
                                <img src="assets/images/ourteam-img4.jpg" width="0" height="0" alt="" title=""/>
                                <h4>Mrs. Maitri Sharma</h4>
                                <p>CEO, Founder, Quick Forex </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="500">
                            <div className="ourteam-div">
                                <img src="assets/images/ourteam-img1.jpg" width="0" height="0" alt="" title=""/>
                                <h4>Mr. Dabajit Ray</h4>
                                <p>CEO, Founder, Quick Forex </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="500">
                            <div className="ourteam-div">
                                <img src="assets/images/ourteam-img2.jpg" width="0" height="0" alt="" title=""/>
                                <h4>Mr. Sanju Singh</h4>
                                <p>CEO, Founder, Quick Forex </p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <Followus />
            <Footer />
            </React.Fragment>
    )
}
export default Ourteam