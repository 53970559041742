import React from 'react'
import { useEffect, useState } from 'react';
import { fetchBanner } from '../../controler/home/Home';
import { imageUrl } from '../../controler/ApiHelper';
function Homebanner() {
	//  const homeslider=[
	// 	{
	// 		images: "assets/images/banner-img1.jpg",
	// 		heading: ["Meenakshi Free", <br/>, "Foreign Exchange Solutions"],
	// 		preg: "maecenas suscipit tortor bibendum libero feugiat elementum.",
	// 		slug:"/about"
	// 	},
	// 	{
	// 		images: "assets/images/banner-img1.jpg",
	// 		heading: ["Sourabh Free", <br/>, "Foreign Exchange Solutions"],
	// 		preg: "maecenas suscipit tortor bibendum libero feugiat elementum.",
	// 		slug:"/about"
	// 	},imageUrl
	// ]
	var url="https://forex.weaddo.com";
	const [bannerData, setBannerData]= useState([])
	useEffect(() => {
		async function getData() {
			const data = await fetchBanner();
			setBannerData(data.data);
			}
			getData()
	 }, []);
    
  return (
    <React.Fragment>
        <div id="myCarousel" className="carousel slide" data-ride="carousel">
					<ol className="carousel-indicators">
						{bannerData.map((slide) =>(
							<li data-target="#myCarousel" data-slide-to={bannerData.indexOf(slide)} className={bannerData.indexOf(slide) === 0? "active" : ""}></li>
							// <li data-target="#myCarousel" data-slide-to="1"></li>
						))}
						{/* <li data-target="#myCarousel" data-slide-to="0" className="active"></li> */}
						
					</ol>
					<div className="carousel-inner">
						{bannerData.map((slide) =>(
							<div className= {bannerData.indexOf(slide) === 0? "item active" : "item"}>
								<img src={imageUrl+slide.bannerImage} width="0" height="0" alt="" title="" />
								<div className="banner-content">
									<div className="homebannercontent">
										<h3 data-aos="fade-up">{slide.title} </h3>
										<p data-aos="fade-up">{slide.description}</p>
									</div>
								</div>
							</div>
						))}
						
						{/* <div className="item active">
							<img src="assets/images/banner-img1.jpg" width="0" height="0" alt="" title="" />
							<div className="banner-content">
								<div className="homebannercontent" data-aos="fade-down">
									<h3 data-aos="fade-up">Hassel Free <br/> Foreign Exchange <br/> Solutions </h3>
									<p data-aos="fade-up">maecenas suscipit tortor bibendum libero feugiat elementum.</p>
								</div>
							</div>
						</div> */}
					</div>
				</div>
    </React.Fragment>
  )
}

export default Homebanner
