import Endpoints from "../Endpoints";
import { baseUrl } from "../ApiHelper";

export const fetchAwardBanner = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.awardbanner}`);
    return await data.json();
};

export const fetchAwardList = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.awardlist}`);
    return await data.json();
};