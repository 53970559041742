import React, { useEffect, useState } from "react";
import Followus from "../footer/Followus";  
import Footer from "../footer/Footer";
import Marqueepagee from "../marquee/Marqueepagee";
import Breadcrumbpage from "../commonpage/Breadcrumbpage";
import Headertop from "../header/Headertop";
import Headermain from "../header/Headermain";
import { fetchFaqBanner, fetchFaqList } from "../../controler/faq/faq";
import { imageUrl } from "../../controler/ApiHelper";
function Faq(){
    // var url = "https://forex.weaddo.com";
    const[faqlist, setFaqList] = useState([])
    const[active, setActive] = useState(0)
    const[faqbanner, setFaqBanner]= useState ([])

    useEffect(() =>{
        async function faqlist(){
            const data = await fetchFaqList();
            setFaqList(data.data);
        }
        faqlist();

        async function faqbanner(){
            const data = await fetchFaqBanner();
            setFaqBanner(data.data[0]);
            console.log("faq banner data", data.data)
        }
        faqbanner();
    },[])
    const handleActive=(val)=>{
console.log("valllllll",val);
setActive(val)
    }
    return(
        <React.Fragment>
            <Headertop />
		<div className="innerbanner-main">
				<Headermain />
				<div id="myCarousel" className="carousel slide" data-ride="carousel">
					<div className="carousel-inner">
						<div className="item active">
						<img src={imageUrl+faqbanner.bannerImg} width="0" height="0" alt="" title=""/>
						<div className="banner-content hidden-xs">
							<div className="homebannercontent">
								<h1>{faqbanner.title}</h1>
								<p>{faqbanner.description}</p>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
		    <Marqueepagee />
            <Breadcrumbpage />
            <div className="faqpage-main" data-aos="fade-up" data-aos-duration="1500">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-offset-2 col-lg-8 col-sm-12">
                            <div className="panel-group" id="accordion1">
                                {faqlist.map(faqlistt => {
                                    return(
                                    <div className="panel">
                                    <div className="panel-heading"  onClick={()=>handleActive(faqlist.indexOf(faqlistt))}>
                                        <h4 className="panel-title">
                                            <a className={faqlist.indexOf(faqlistt)=== active ? "accordion-toggle1":"accordion-toggle1 collapsed"} data-toggle="collapse" data-parent="#accordion1" 
                                            href="#panelBody1">{faqlistt.title}</a>
                                        </h4>
                                    </div>
                                    <div id={faqlist.indexOf(faqlistt)} className={faqlist.indexOf(faqlistt)=== active?"panel-collapse collapse in":"panel-collapse collapse"}>
                                        <div className="panel-body">
                                            <p>{faqlistt.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                )
                                })}
                                {/* <div className="panel">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="accordion-toggle1" data-toggle="collapse" data-parent="#accordion1 " href="#panelBody1">Nam imperdiet sem in fringilla placerat.</a>
                                        </h4>
                                    </div>
                                    <div id="panelBody1" className="panel-collapse collapse in">
                                        <div className="panel-body">
                                            <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                                the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                                handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="accordion-toggle1 collapsed" data-toggle="collapse" data-parent="#accordion1" href="#panelBody2">
                                                Integer sit amet odio id enim hendrerit volutpat vel sed dolor.
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="panelBody2" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                                        the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                                        handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="accordion-toggle1 collapsed" data-toggle="collapse" data-parent="#accordion1" href="#panelBody3">
                                                Integer sit amet odio id enim hendrerit volutpat vel sed dolor.
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="panelBody3" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                                        the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                                        handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="accordion-toggle1 collapsed" data-toggle="collapse" data-parent="#accordion1" href="#panelBody4">
                                                Integer sit amet odio id enim hendrerit volutpat vel sed dolor.
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="panelBody4" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this 
                                                        the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a 
                                                        handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. 
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Followus />
            <Footer />
        </React.Fragment>
    )
}

export default Faq