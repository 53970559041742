import Endpoints from "../Endpoints";
import { baseUrl } from "../ApiHelper";

export const fetchFooterMain = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.footermain}`);
    return await data.json();
};

export const fetchFollowUs = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.followus}`);
    return await data.json();
};

export const fetchGetInTouch = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.getintouch}`);
    return await data.json();
};

export const fetchFooterLinks = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.footerlinks}`);
    return await data.json();
};

export const fetchCopyRight = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.copyright}`);
    return await data.json();
};

export const fetchNewsLetterForm = async (item) => {
    const data = await fetch(`${baseUrl}/${Endpoints.newsletterform}`, {
    method: 'POST', 
    headers: {
        'Content-Type': 'application/json'
      },
    mode: 'cors', 
    body: JSON.stringify(item)

  });
    return await data.json();
};